import { useDispatch } from "@lookiero/messaging.js";
import { useCallback } from "react";
import invariant from "ts-invariant";
import SetLooks from "../../../core/domain/look/command/setLooks/SetLooks";
import { LookProjection } from "../../../core/projection/looks/look";

const useSetLooks = (selectionId: string | undefined): ((looks: LookProjection[]) => Promise<void>) => {
  const dispatch = useDispatch();
  const setLooks = useCallback(
    async (looks: LookProjection[]) => {
      invariant(selectionId, "Cannot set the Looks without a selectionId");

      await dispatch(new SetLooks(selectionId, looks));
    },
    [dispatch, selectionId],
  );

  return setLooks;
};

export default useSetLooks;
