import { CommandHandler } from "@lookiero/messaging.js";
import { QueryBus as MessagingQueryBus } from "@lookiero/messaging";
import Boxes from "../../model/Boxes";
import Box from "../../model/Box";
import CloseBox from "./CloseBox";
import { canTheBoxBeClosed, CanTheBoxBeClosedResult } from "../../../../projection/box/canTheBoxBeClosed";

class CloseBoxHandler implements CommandHandler<CloseBox> {
  private readonly messagingQueryBus!: MessagingQueryBus;
  private readonly boxes: Boxes;

  public constructor(boxes: Boxes) {
    this.boxes = boxes;
  }

  public async execute({ legacyBoxId }: CloseBox): Promise<void> {
    const box: Box | undefined = await this.boxes.byLegacyBoxId(legacyBoxId);

    if (!box) {
      throw new Error("Cannot close the box. The box does not exist");
    }

    const canTheBoxBeClosedResult: CanTheBoxBeClosedResult = await this.messagingQueryBus(
      canTheBoxBeClosed({ boxNumber: legacyBoxId }),
    );

    if (!canTheBoxBeClosedResult) {
      throw new Error("Cannot close the box.");
    }

    box.close();

    await this.boxes.close(box);
  }
}

export default CloseBoxHandler;
