import { DomainEvent } from "@lookiero/messaging.js";

class LooksSet extends DomainEvent {
  public readonly selectionId: string;

  public constructor(selectionId: string) {
    super();

    this.selectionId = selectionId;
  }

  public messageName(): string {
    return "LooksSet";
  }
}

export default LooksSet;
