import { Command } from "@lookiero/messaging.js";
import BoxPreviewStatus from "../../../boxPreview/model/BoxPreviewStatus";

type SelectProductVariantParameters = {
  readonly legacyBoxId: string;
  readonly boxId: string;
  readonly selectionId: string;
  readonly productVariantId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
};

class SelectProductVariant extends Command {
  public readonly legacyBoxId: string;
  public readonly boxId: string;
  public readonly selectionId: string;
  public readonly productVariantId: string;
  public readonly boxPreviewStatus: BoxPreviewStatus | undefined;

  public constructor({
    legacyBoxId,
    boxId,
    selectionId,
    productVariantId,
    boxPreviewStatus,
  }: SelectProductVariantParameters) {
    super();
    this.legacyBoxId = legacyBoxId;
    this.boxId = boxId;
    this.selectionId = selectionId;
    this.productVariantId = productVariantId;
    this.boxPreviewStatus = boxPreviewStatus;
  }

  protected messageName(): string {
    return "SelectProductVariant";
  }
}

export default SelectProductVariant;
