import { QueryStatus } from "@lookiero/messaging-react";
import { useState, useEffect } from "react";
import { useUpdateUiSetting } from "../../../core/infrastructure/domain/uiSetting/react/useUpdateUiSetting";
import { box } from "../../../core/infrastructure/projection/box/box.mock";
import { useViewUiSettingByKey } from "../../../core/infrastructure/projection/uiSetting/react/useViewUiSettingByKey";
import UISettings from "../../_settings/UISettings";
import { useAutomaticSelection, AutomaticSelectionStatus } from "./useAutomaticSelection";

const AUTOMATIC_SELECTION_TOOLTIP_TIMEOUT = 10000;

interface UseAutomaticSelectionTooltipVisibleFunction {
  (): boolean;
}

const useAutomaticSelectionTooltipVisible: UseAutomaticSelectionTooltipVisibleFunction = () => {
  const [automaticSelectionTooltipVisible, setAutomaticSelectionTooltipVisible] = useState(false);

  const { automaticSelectionStatus, selection } = useAutomaticSelection();

  const automaticSelectionShownSettingKey = `${UISettings.AUTOMATIC_SELECTION_FINISHED_SHOWN}_${box.id}`;
  const [automaticSelectionShownSetting, automaticSelectionShownSettingStatus] = useViewUiSettingByKey({
    key: automaticSelectionShownSettingKey,
  });
  const [updateUiSetting] = useUpdateUiSetting();

  useEffect(() => {
    if (
      automaticSelectionShownSettingStatus === QueryStatus.LOADING ||
      automaticSelectionShownSetting?.value ||
      automaticSelectionStatus !== AutomaticSelectionStatus.FINISHED ||
      !selection ||
      selection.products.length === 0
    ) {
      return;
    }

    setAutomaticSelectionTooltipVisible(true);
    updateUiSetting({ key: automaticSelectionShownSettingKey, value: true });
  }, [
    automaticSelectionShownSetting,
    automaticSelectionShownSettingKey,
    automaticSelectionShownSettingStatus,
    automaticSelectionStatus,
    selection,
    updateUiSetting,
  ]);

  useEffect(() => {
    if (!automaticSelectionTooltipVisible) {
      return;
    }

    const hideAutomaticSelectionTooltipTimeout = setTimeout(
      () => setAutomaticSelectionTooltipVisible(false),
      AUTOMATIC_SELECTION_TOOLTIP_TIMEOUT,
    );

    return () => {
      clearTimeout(hideAutomaticSelectionTooltipTimeout);
    };
  }, [automaticSelectionTooltipVisible]);

  return automaticSelectionTooltipVisible;
};

export { useAutomaticSelectionTooltipVisible };
