import { AggregateRoot } from "@lookiero/messaging.js";
import BoxClosed from "../event/BoxClosed";
import BoxSentToSupervise from "../event/BoxSentToSupervise";
import BoxStatus from "./BoxStatus";

class Box extends AggregateRoot {
  public readonly id: string;
  public readonly legacyId: string;
  public status: BoxStatus;

  public constructor(id: string, legacyId: string, status: BoxStatus) {
    super();

    this.id = id;
    this.legacyId = legacyId;
    this.status = status;
  }

  public sendToSupervise(): void {
    this.status = BoxStatus.SUPERVISING;
    this.record(new BoxSentToSupervise({ boxId: this.id }));
  }

  public close(): void {
    this.status = BoxStatus.CLOSED;
    this.record(new BoxClosed({ boxId: this.id, legacyBoxId: this.legacyId }));
  }
}

export default Box;
