import { Command } from "@lookiero/messaging.js";
import BoxPreviewStatus from "../../../boxPreview/model/BoxPreviewStatus";

type DeselectProductVariantParameters = {
  readonly legacyBoxId: string;
  readonly productVariantId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
};

class DeselectProductVariant extends Command {
  public readonly legacyBoxId: string;
  public readonly productVariantId: string;
  public readonly boxPreviewStatus: BoxPreviewStatus | undefined;

  public constructor({ legacyBoxId, productVariantId, boxPreviewStatus }: DeselectProductVariantParameters) {
    super();
    this.legacyBoxId = legacyBoxId;
    this.productVariantId = productVariantId;
    this.boxPreviewStatus = boxPreviewStatus;
  }

  protected messageName(): string {
    return "DeselectProductVariant";
  }
}

export default DeselectProductVariant;
