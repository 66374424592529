import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import { QueryBus as MessagingQueryBus } from "@lookiero/messaging";
import ProductVariantReplaced from "../../selection/event/ProductVariantReplaced";
import SetLooks from "../command/setLooks/SetLooks";
import { ListLooksBySelectionIdResult, listLooksBySelectionId } from "../../../projection/looks/listLooksBySelectionId";

class SetLooksWhenProductVariantReplaced implements ProcessManager<ProductVariantReplaced> {
  private readonly commandBus!: CommandBus;
  private readonly messagingQueryBus!: MessagingQueryBus;

  public async process({
    selectionId,
    selectedProductVariantId,
    deselectedProductVariantId,
  }: ProductVariantReplaced): Promise<void> {
    const looks: ListLooksBySelectionIdResult = await this.messagingQueryBus(listLooksBySelectionId({ selectionId }));

    const looksWithoutProductVariant =
      looks?.map((look) =>
        look.map((productVariantId) =>
          productVariantId === deselectedProductVariantId ? selectedProductVariantId : productVariantId,
        ),
      ) || [];

    this.commandBus.dispatch(new SetLooks(selectionId, looksWithoutProductVariant));
  }
}

export default SetLooksWhenProductVariantReplaced;
