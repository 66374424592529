import { CommandHandler } from "@lookiero/messaging.js";
import Selection from "../../model/Selection";
import Selections from "../../model/Selections";
import ReplaceProductVariant from "./ReplaceProductVariant";

class ReplaceProductVariantHandler implements CommandHandler<ReplaceProductVariant> {
  private readonly selections: Selections;

  public constructor(selections: Selections) {
    this.selections = selections;
  }

  public async execute({
    legacyBoxId,
    selectedProductVariantId,
    deselectedProductVariantId,
    boxPreviewStatus,
  }: ReplaceProductVariant): Promise<void> {
    const selection: Selection | undefined = await this.selections.byLegacyBoxId(legacyBoxId);

    if (!selection) {
      throw new Error(`Selection for the provided legacy-box-id does not exist: ${legacyBoxId}`);
    }

    selection.replaceProductVariant({
      selectedProductVariantId,
      deselectedProductVariantId,
      boxPreviewStatus,
    });

    await this.selections.replaceProductVariant(selection, selectedProductVariantId, deselectedProductVariantId);
  }
}

export default ReplaceProductVariantHandler;
