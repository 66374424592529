import { EventBus } from "@lookiero/messaging.js";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import Box from "../../../../domain/box/model/Box";
import Boxes from "../../../../domain/box/model/Boxes";
import {
  boxByLegacyBoxIdRequestPayload,
  closeBoxRequestPayload,
  sendBoxToSuperviseRequestPayload,
} from "./requestBuilder";
import { boxFromResponse } from "./responseMapper";
import { camelcaseKeys } from "../../../../../shared/delivery/infrastructure/camelcaseKeys";

class HttpBoxes implements Boxes {
  private readonly httpClient: HttpClient;
  private readonly eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async byLegacyBoxId(legacyBoxId: string): Promise<Box | undefined> {
    try {
      const requestPayload = boxByLegacyBoxIdRequestPayload(legacyBoxId);

      const response = await this.httpClient.post("/view-box-by-box-number", requestPayload);

      if (!response.ok) {
        if (response.status === 404) {
          return undefined;
        }

        throw new Error("View Box by Legacy Box Id");
      }

      const { result: boxResponse } = await response.json();

      const box: Box = boxFromResponse(camelcaseKeys(boxResponse));

      return box;
    } catch (error) {
      console.error(error);
      throw new Error("View Box by Legacy Box Id");
    }
  }

  public async close(box: Box): Promise<void> {
    try {
      const requestPayload = closeBoxRequestPayload(box.id);

      const response = await this.httpClient.post("/close-box", requestPayload);

      if (!response.ok) {
        throw new Error("Close Box");
      }

      this.eventBus.publish(box.recordedEvents());
    } catch (error) {
      console.error(error);
      throw new Error("Close Box");
    }
  }

  public async sendToSupervise(box: Box, psId: string): Promise<void> {
    try {
      const requestPayload = sendBoxToSuperviseRequestPayload({ boxId: box.id, personalShopperId: psId });
      const response = await this.httpClient.post("/send-box-to-supervise", requestPayload);

      if (!response.ok) {
        throw new Error("Send Box to Supervise");
      }

      this.eventBus.publish(box.recordedEvents());
    } catch (error) {
      console.error(error);
      throw new Error("Send Box to Supervise");
    }
  }
}

export default HttpBoxes;
