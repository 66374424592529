import { DomainEvent } from "@lookiero/messaging.js";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { useMessagingJsBridgeQuery } from "../../_messagingJsBridge/useMessagingJsBridgeQuery";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import SelectionOrderRemoved from "../../../../domain/selectionOrder/event/SelectionOrderRemoved";
import SelectionSorted from "../../../../domain/selectionOrder/event/SelectionSorted";
import { viewSelectionOrderByBoxNumber } from "../../../../projection/selectionOrder/viewSelectionOrderByBoxNumber";
import { SelectionOrderProjection } from "../../../../projection/selectionOrder/selectionOrder";

const shouldInvalidate = (event: DomainEvent) =>
  event instanceof SelectionOrderRemoved || event instanceof SelectionSorted;

interface UseViewSelectionOrderByBoxNumberFunctionArgs {
  readonly boxNumber: string;
}

interface UseViewSelectionOrderByBoxNumberFunction {
  (args: UseViewSelectionOrderByBoxNumberFunctionArgs): UseQueryFunctionResult<SelectionOrderProjection>;
}

const useViewSelectionOrderByBoxNumber: UseViewSelectionOrderByBoxNumberFunction = ({ boxNumber }) =>
  useMessagingJsBridgeQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewSelectionOrderByBoxNumber({ boxNumber }),
    messagingJsInvalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  });

export { useViewSelectionOrderByBoxNumber };
