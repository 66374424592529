import Box from "../../../../domain/box/model/Box";
import BoxStatus from "../../../../domain/box/model/BoxStatus";

type BoxResponse = {
  readonly id: string;
  readonly legacyId: string;
  readonly status: string;
};

const boxFromResponse = ({ id, legacyId, status }: BoxResponse): Box => new Box(id, legacyId, status as BoxStatus);

export { boxFromResponse };
