import Country from "../../../../../core/domain/country/model/Country";
import { ACTION_TIME, DEFAULT_ACTION_TIME } from "./actionTimes";
import {
  ACTION_TIME as ACTION_TIME_NOTE_ASSISTANT,
  DEFAULT_ACTION_TIME as DEFAULT_ACTION_TIME_NOTE_ASSISTANT,
} from "./actionTimesWithNoteAssistantEnabled";

interface ActionTimeForCountryByPreviewSelectionRateFunctionArgs {
  readonly country: Country | undefined;
  readonly selectionRate: number | null | undefined;
  readonly noteAssistantEnabled: boolean | undefined;
}

interface ActionTimeForCountryByPreviewSelectionRateFunction {
  (args: ActionTimeForCountryByPreviewSelectionRateFunctionArgs): number | undefined; // seconds
}

const actionTimeForCountryByPreviewSelectionRate: ActionTimeForCountryByPreviewSelectionRateFunction = ({
  noteAssistantEnabled,
  selectionRate,
  country,
}) => {
  if (!country) {
    return undefined;
  }

  if (noteAssistantEnabled) {
    if (selectionRate !== null && selectionRate !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return ACTION_TIME_NOTE_ASSISTANT[selectionRate][country] * 60;
    }

    return DEFAULT_ACTION_TIME_NOTE_ASSISTANT[country] * 60;
  }

  if (selectionRate !== null && selectionRate !== undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return ACTION_TIME[selectionRate][country] * 60;
  }

  return DEFAULT_ACTION_TIME[country] * 60;
};

export { actionTimeForCountryByPreviewSelectionRate };
