import { EventBus } from "@lookiero/messaging.js";
import LocalStorage from "../../../../../shared/storage/infrastructure/domain/model/LocalStorage";
import SelectionOrderRemoved from "../../../../domain/selectionOrder/event/SelectionOrderRemoved";
import SelectionOrder from "../../../../domain/selectionOrder/model/SelectionOrder";
import SelectionOrders from "../../../../domain/selectionOrder/model/SelectionOrders";
import SelectionOrderDTO from "./SelectionOrderDTO";

class LocalStorageSelectionOrders implements SelectionOrders {
  private readonly storage: LocalStorage<SelectionOrderDTO>;
  private readonly eventBus!: EventBus;

  public constructor(storage: LocalStorage<SelectionOrderDTO>) {
    this.storage = storage;
  }

  private toSelectionOrderDTO(selectionOrder: SelectionOrder): SelectionOrderDTO {
    return {
      legacyBoxId: selectionOrder.legacyBoxId,
      selectionId: selectionOrder.selectionId,
      productVariantIds: selectionOrder.productVariantIds,
    };
  }

  public async byLegacyBoxId(legacyBoxId: string): Promise<SelectionOrder | undefined> {
    const selectionOrderDto = await this.storage.read(legacyBoxId);

    return selectionOrderDto
      ? new SelectionOrder({
          legacyBoxId: selectionOrderDto.legacyBoxId,
          selectionId: selectionOrderDto.selectionId,
          productVariantIds: selectionOrderDto.productVariantIds,
        })
      : undefined;
  }

  public async sort(selectionOrder: SelectionOrder): Promise<void> {
    await this.storage.write(selectionOrder.legacyBoxId, this.toSelectionOrderDTO(selectionOrder));

    this.eventBus.publish(selectionOrder.recordedEvents());
  }

  public async remove(legacyBoxId: string): Promise<void> {
    await this.storage.remove(legacyBoxId);

    this.eventBus.publish([new SelectionOrderRemoved(legacyBoxId)]);
  }
}

export default LocalStorageSelectionOrders;
