import Storage from "../../../../storage/domain/model/Storage";

class LocalStorage<T> implements Storage<T> {
  private readonly storageKey: string;

  public constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  private itemKey(key: string): string {
    return `${this.storageKey}_${key}`;
  }

  public async read(key: string): Promise<T | undefined> {
    const value = await localStorage.getItem(this.itemKey(key));

    return value ? JSON.parse(value) : undefined;
  }

  public async write(key: string, value: T): Promise<void> {
    localStorage.setItem(this.itemKey(key), JSON.stringify(value));
  }

  public async remove(key: string): Promise<void> {
    localStorage.removeItem(this.itemKey(key));
  }
}

export default LocalStorage;
