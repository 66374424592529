import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import BoxPreviewSent from "../../boxPreview/event/BoxPreviewSent";
import SetLooks from "../command/setLooks/SetLooks";

class SetLooksWhenBoxPreviewSent implements ProcessManager<BoxPreviewSent> {
  private readonly commandBus!: CommandBus;

  public async process({ selectionId }: BoxPreviewSent): Promise<void> {
    this.commandBus.dispatch(new SetLooks(selectionId, []));
  }
}

export default SetLooksWhenBoxPreviewSent;
