import { Storage } from "../../../../functionalStorage/storage";
import { ListNotificationsView } from "../../../projection/notification/listNotifications";
import { NotificationDto } from "../../persistence/notificationDto";
import { toNotificationProjection } from "./notification";

interface StorageListNotificationsView extends ListNotificationsView {}

interface StorageListNotificationsViewFunctionArgs {
  readonly storage: Storage<NotificationDto>;
}

interface StorageListNotificationsViewFunction {
  (args: StorageListNotificationsViewFunctionArgs): StorageListNotificationsView;
}

const storageListNotificationsView: StorageListNotificationsViewFunction =
  ({ storage }) =>
  async () => {
    const notificationDtos = await storage.list();

    return notificationDtos.map(toNotificationProjection);
  };

export { storageListNotificationsView };
