import { DomainEvent } from "@lookiero/messaging.js";

type BoxClosedParameters = {
  readonly boxId: string;
  readonly legacyBoxId: string;
};

class BoxClosed extends DomainEvent {
  public readonly boxId: string;
  public readonly legacyBoxId: string;

  public constructor({ boxId, legacyBoxId }: BoxClosedParameters) {
    super();

    this.boxId = boxId;
    this.legacyBoxId = legacyBoxId;
  }

  protected messageName(): string {
    return "BoxClosed";
  }
}

export default BoxClosed;
