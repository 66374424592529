import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { isTheBoxOpen, IsTheBoxOpenResult } from "../../../../projection/box/isTheBoxOpen";
import { DomainEvent } from "@lookiero/messaging.js";
import BoxClosed from "../../../../domain/box/event/BoxClosed";
import { useMessagingJsBridgeQuery } from "../../_messagingJsBridge/useMessagingJsBridgeQuery";

const shouldInvalidate = (event: DomainEvent) => event instanceof BoxClosed;

interface UseIsTheBoxOpenFunctionArgs {
  readonly boxId: string;
}

interface UseIsTheBoxOpenFunction {
  (args: UseIsTheBoxOpenFunctionArgs): UseQueryFunctionResult<IsTheBoxOpenResult>;
}

const useIsTheBoxOpen: UseIsTheBoxOpenFunction = ({ boxId }) =>
  useMessagingJsBridgeQuery({
    query: isTheBoxOpen({ boxId }),
    messagingJsInvalidation: shouldInvalidate,
    contextId: MESSAGING_CONTEXT_ID,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

export { useIsTheBoxOpen };
