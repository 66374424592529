import { useDispatch } from "@lookiero/messaging.js";
import { useCallback } from "react";
import invariant from "ts-invariant";
import SortSelection from "../../../core/domain/selectionOrder/command/sortSelection/SortSelection";

type UseSortSelectionParameters = {
  readonly legacyBoxId: string;
  readonly selectionId: string | undefined;
};
const useSortSelection = ({
  legacyBoxId,
  selectionId,
}: UseSortSelectionParameters): ((productVariantIds: string[]) => Promise<void>) => {
  const dispatch = useDispatch();
  const sortSelection = useCallback(
    async (productVariantIds: string[]) => {
      invariant(selectionId, "Cannot sort the selection without a selectionId");

      await dispatch(
        new SortSelection({
          legacyBoxId,
          selectionId,
          productVariantIds,
        }),
      );
    },
    [dispatch, legacyBoxId, selectionId],
  );

  return sortSelection;
};

export default useSortSelection;
