import { DomainEvent } from "@lookiero/messaging.js";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { SelectionProjection } from "../../../../projection/selection/selection";
import { useMessagingJsBridgeQuery } from "../../_messagingJsBridge/useMessagingJsBridgeQuery";
import { viewSelectionByBoxNumber } from "../../../../projection/selection/viewSelectionByBoxNumber";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import ProductVariantDeselected from "../../../../domain/selection/event/ProductVariantDeselected";
import ProductVariantMarkedAsCandidate from "../../../../domain/selection/event/ProductVariantMarkedAsCandidate";
import ProductVariantReplaced from "../../../../domain/selection/event/ProductVariantReplaced";
import ProductVariantSelected from "../../../../domain/selection/event/ProductVariantSelected";
import ProductVariantUnmarkedAsCandidate from "../../../../domain/selection/event/ProductVariantUnmarkedAsCandidate";
import { isAutomaticSelectionStarted } from "../../../../domain/selection/model/automaticSelectionStarted";

const shouldInvalidate = (event: DomainEvent) =>
  event instanceof ProductVariantSelected ||
  event instanceof ProductVariantDeselected ||
  event instanceof ProductVariantReplaced ||
  event instanceof ProductVariantMarkedAsCandidate ||
  event instanceof ProductVariantUnmarkedAsCandidate;

interface UseViewSelectionByBoxNumberFunctionArgs {
  readonly boxNumber: string;
  readonly queryId?: string | string[];
}

interface UseViewSelectionByBoxNumberFunction {
  (args: UseViewSelectionByBoxNumberFunctionArgs): UseQueryFunctionResult<SelectionProjection>;
}

const useViewSelectionByBoxNumber: UseViewSelectionByBoxNumberFunction = ({ boxNumber, queryId }) =>
  useMessagingJsBridgeQuery({
    contextId: MESSAGING_CONTEXT_ID,
    id: queryId,
    query: viewSelectionByBoxNumber({ boxNumber }),
    messagingJsInvalidation: shouldInvalidate,
    invalidation: isAutomaticSelectionStarted,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useViewSelectionByBoxNumber };
