import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import { QueryBus as MessagingQueryBus } from "@lookiero/messaging";
import ProductVariantDeselected from "../../selection/event/ProductVariantDeselected";
import SetLooks from "../command/setLooks/SetLooks";
import { ListLooksBySelectionIdResult, listLooksBySelectionId } from "../../../projection/looks/listLooksBySelectionId";

class SetLooksWhenProductVariantDeselected implements ProcessManager<ProductVariantDeselected> {
  private readonly commandBus!: CommandBus;
  private readonly messagingQueryBus!: MessagingQueryBus;

  public async process({ selectionId, productVariantId }: ProductVariantDeselected): Promise<void> {
    const looks: ListLooksBySelectionIdResult = await this.messagingQueryBus(listLooksBySelectionId({ selectionId }));

    const looksWithoutProductVariant = looks?.filter((look) => !look.includes(productVariantId)) || [];

    this.commandBus.dispatch(new SetLooks(selectionId, looksWithoutProductVariant));
  }
}

export default SetLooksWhenProductVariantDeselected;
