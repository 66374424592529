import { useDispatch } from "@lookiero/messaging.js";
import { useCallback, useState } from "react";
import AsyncActionState from "../../../shared/ui/uiKit/_common/AsyncActionState";
import SendBoxToSupervise from "../../../core/domain/box/command/sendBoxToSupervise/SendBoxToSupervise";

type UseSendBoxToSuperviseParameters = {
  readonly legacyBoxId: string;
  readonly personalShopperId: string | undefined;
};
type UseSendBoxToSuperviseReturn = {
  readonly state: AsyncActionState;
  readonly sendBoxToSupervise: () => void;
};
const useSendBoxToSupervise = ({
  legacyBoxId,
  personalShopperId,
}: UseSendBoxToSuperviseParameters): UseSendBoxToSuperviseReturn => {
  const dispatch = useDispatch();
  const [state, setState] = useState<AsyncActionState>(AsyncActionState.DEFAULT);

  const sendBoxToSupervise = useCallback(async () => {
    try {
      if (!personalShopperId) {
        throw new Error("Cannot dispatch SendBoxToSupervise: No personalShopperId provided");
      }

      setState(AsyncActionState.PENDING);

      await dispatch(
        new SendBoxToSupervise({
          legacyBoxId,
          personalShopperId,
        }),
      );

      setState(AsyncActionState.SUCCESS);
    } catch (error) {
      console.log(error);
      setState(AsyncActionState.ERROR);
    }
  }, [dispatch, legacyBoxId, personalShopperId]);

  return { state, sendBoxToSupervise };
};

export default useSendBoxToSupervise;
