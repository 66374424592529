type ViewSelectionByLegacyBoxIdRequestPayload = {
  readonly legacy_box_id: string;
};
const viewSelectionByLegacyBoxIdRequestPayload = (legacyBoxId: string): ViewSelectionByLegacyBoxIdRequestPayload => ({
  legacy_box_id: legacyBoxId,
});

type SelectProductVariantRequestPayload = {
  readonly selection_id: string;
  readonly box_id: string;
  readonly product_variant_id: string;
};
const selectProductVariantRequestPayload = (
  selectionId: string,
  boxId: string,
  productVariantId: string,
): SelectProductVariantRequestPayload => ({
  selection_id: selectionId,
  box_id: boxId,
  product_variant_id: productVariantId,
});

type DeselectProductVariantRequestPayload = {
  readonly selection_id: string;
  readonly product_variant_id: string;
};
const deselectProductVariantRequestPayload = (
  selectionId: string,
  productVariantId: string,
): DeselectProductVariantRequestPayload => ({
  selection_id: selectionId,
  product_variant_id: productVariantId,
});

type ReplaceProductVariantRequestPayload = {
  readonly box_id: string;
  readonly selection_id: string;
  readonly select_product_variant_id: string;
  readonly deselect_product_variant_id: string;
};
const replaceProductVariantRequestPayload = (
  boxId: string,
  selectionId: string,
  selectProductVariantId: string,
  deselectProductVariantId: string,
): ReplaceProductVariantRequestPayload => ({
  box_id: boxId,
  selection_id: selectionId,
  select_product_variant_id: selectProductVariantId,
  deselect_product_variant_id: deselectProductVariantId,
});

type MarkProductVariantAsCandidateRequestPayload = {
  readonly selection_id: string;
  readonly product_variant_id: string;
};
const markProductVariantAsCandidateRequestPayload = (
  selectionId: string,
  productVariantId: string,
): MarkProductVariantAsCandidateRequestPayload => ({
  selection_id: selectionId,
  product_variant_id: productVariantId,
});

type UnmarkProductVariantAsCandidateRequestPayload = {
  readonly selection_id: string;
  readonly product_variant_id: string;
};
const unmarkProductVariantAsCandidateRequestPayload = (
  selectionId: string,
  productVariantId: string,
): UnmarkProductVariantAsCandidateRequestPayload => ({
  selection_id: selectionId,
  product_variant_id: productVariantId,
});

export {
  viewSelectionByLegacyBoxIdRequestPayload,
  selectProductVariantRequestPayload,
  deselectProductVariantRequestPayload,
  replaceProductVariantRequestPayload,
  markProductVariantAsCandidateRequestPayload,
  unmarkProductVariantAsCandidateRequestPayload,
};
