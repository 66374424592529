import { useDispatch } from "@lookiero/messaging.js";
import { useCallback, useState } from "react";
import AsyncActionState from "../../../shared/ui/uiKit/_common/AsyncActionState";
import ReplaceProductVariant from "../../../core/domain/selection/command/replaceProductVariant/ReplaceProductVariant";
import BoxPreviewStatus from "../../../core/domain/boxPreview/model/BoxPreviewStatus";
import { ProductVariantProjection } from "../../../core/projection/product/productVariant";

type UseReplaceProductVariantParameters = {
  readonly legacyBoxId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
};
type UseReplaceProductVariantReturn = {
  readonly state: AsyncActionState;
  readonly replaceProductVariant: (
    selectedProductVariantId: ProductVariantProjection,
    deselectedProductVariantId: ProductVariantProjection,
  ) => Promise<void>;
};
const useReplaceProductVariant = ({
  legacyBoxId,
  boxPreviewStatus,
}: UseReplaceProductVariantParameters): UseReplaceProductVariantReturn => {
  const dispatch = useDispatch();
  const [state, setState] = useState(AsyncActionState.DEFAULT);

  const replaceProductVariant = useCallback(
    async (
      selectedProductVariantId: ProductVariantProjection,
      deselectedProductVariantId: ProductVariantProjection,
    ) => {
      try {
        setState(AsyncActionState.PENDING);

        await dispatch(
          new ReplaceProductVariant({
            legacyBoxId,
            selectedProductVariantId: selectedProductVariantId.id,
            deselectedProductVariantId: deselectedProductVariantId.id,
            boxPreviewStatus,
          }),
        );

        setState(AsyncActionState.SUCCESS);
      } catch (error) {
        console.log(error);
        setState(AsyncActionState.ERROR);
        throw error;
      }
    },
    [boxPreviewStatus, dispatch, legacyBoxId],
  );

  return { state, replaceProductVariant };
};

export default useReplaceProductVariant;
