import { DomainEvent as DomainEventJs } from "@lookiero/messaging.js";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import BoxClosed from "../../../../domain/box/event/BoxClosed";
import BoxSentToSupervise from "../../../../domain/box/event/BoxSentToSupervise";
import ProductVariantDeselected from "../../../../domain/selection/event/ProductVariantDeselected";
import ProductVariantMarkedAsCandidate from "../../../../domain/selection/event/ProductVariantMarkedAsCandidate";
import ProductVariantReplaced from "../../../../domain/selection/event/ProductVariantReplaced";
import ProductVariantSelected from "../../../../domain/selection/event/ProductVariantSelected";
import ProductVariantUnmarkedAsCandidate from "../../../../domain/selection/event/ProductVariantUnmarkedAsCandidate";
import { isNoteGenerated } from "../../../../domain/note/model/noteGenerated";
import { isNoteWritten } from "../../../../domain/note/model/noteWritten";
import { useMessagingJsBridgeQuery } from "../../_messagingJsBridge/useMessagingJsBridgeQuery";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import {
  canTheBoxSentToSupervise,
  CanTheBoxSentToSuperviseResult,
} from "../../../../projection/box/canTheBoxSentToSupervise";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isNoteWritten(event) || isNoteGenerated(event);

interface UseCanTheBoxBeSentToSuperviceFunctionArgs {
  readonly boxNumber: string;
}

interface UseCanTheBoxBeSentToSuperviceFunction {
  (args: UseCanTheBoxBeSentToSuperviceFunctionArgs): UseQueryFunctionResult<CanTheBoxSentToSuperviseResult>;
}

const useCanTheBoxBeSentToSupervise: UseCanTheBoxBeSentToSuperviceFunction = ({ boxNumber }) =>
  useMessagingJsBridgeQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: canTheBoxSentToSupervise({ boxNumber }),
    messagingJsInvalidation: (event: DomainEventJs) =>
      event instanceof ProductVariantSelected ||
      event instanceof ProductVariantDeselected ||
      event instanceof ProductVariantReplaced ||
      event instanceof ProductVariantMarkedAsCandidate ||
      event instanceof ProductVariantUnmarkedAsCandidate ||
      event instanceof BoxClosed ||
      event instanceof BoxSentToSupervise,
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useCanTheBoxBeSentToSupervise };
