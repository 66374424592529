import { useDispatch } from "@lookiero/messaging.js";
import { useCallback, useState } from "react";
import AsyncActionState from "../../../shared/ui/uiKit/_common/AsyncActionState";
import DeselectProductVariant from "../../../core/domain/selection/command/deselectProductVariant/DeselectProductVariant";
import BoxPreviewStatus from "../../../core/domain/boxPreview/model/BoxPreviewStatus";
import { ProductVariantProjection } from "../../../core/projection/product/productVariant";

type UseDeselectProductVariantParameters = {
  readonly legacyBoxId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
};
type UseDeselectProductVariantReturn = {
  readonly state: AsyncActionState;
  readonly deselectProductVariant: (productVariant: ProductVariantProjection) => Promise<void>;
};
const useDeselectProductVariant = ({
  legacyBoxId,
  boxPreviewStatus,
}: UseDeselectProductVariantParameters): UseDeselectProductVariantReturn => {
  const dispatch = useDispatch();
  const [state, setState] = useState(AsyncActionState.DEFAULT);

  const deselectProductVariant = useCallback(
    async (productVariant: ProductVariantProjection) => {
      try {
        setState(AsyncActionState.PENDING);

        await dispatch(
          new DeselectProductVariant({
            legacyBoxId,
            productVariantId: productVariant.id,
            boxPreviewStatus,
          }),
        );

        setState(AsyncActionState.SUCCESS);
      } catch (error) {
        console.log(error);
        setState(AsyncActionState.ERROR);
      }
    },
    [boxPreviewStatus, dispatch, legacyBoxId],
  );

  return { state, deselectProductVariant };
};

export default useDeselectProductVariant;
