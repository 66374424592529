const TYPES = {
  MessagingRootComponent: Symbol.for("MessagingRootComponent"),
  QueryBus: Symbol.for("QueryBus"),
  CommandBus: Symbol.for("CommandBus"),
  DomainEventBus: Symbol.for("DomainEventBus"),
  UIEventBus: Symbol.for("UIEventBus"),

  MessagingQueryBus: Symbol.for("MessagingQueryBus"),
  MessagingCommandBus: Symbol.for("MessagingCommandBus"),

  I18nRootComponent: Symbol.for("I18nRootComponent"),

  Logger: Symbol.for("Logger"),
  Environment: Symbol.for("Environment"),
  CookieStorage: Symbol.for("CookieStorage"),
  HttpClient: Symbol.for("HttpClient"),
  Navigator: Symbol.for("Navigator"),
  Tracker: Symbol.for("Tracker"),

  WindowsMessenger: Symbol.for("WindowsMessenger"),
  LabsFrontLegacyWindowsMessenger: Symbol.for("LabsFrontLegacyWindowsMessenger"),
  CustomerWindowsMessenger: Symbol.for("CustomerWindowsMessenger"),
  RealTimeBoxProductionAuthCookieBasedFeatureToggle: Symbol.for("RealTimeBoxProductionAuthCookieBasedFeatureToggle"),
  RealTimeBoxProductionRouteFirewall: Symbol.for("RealTimeBoxProductionRouteFirewall"),
  PlanningAuthCookieBasedFeatureToggle: Symbol.for("PlanningAuthCookieBasedFeatureToggle"),
  CapacityAuthCookieBasedFeatureToggle: Symbol.for("CapacityAuthCookieBasedFeatureToggle"),
  StockSurveyTypeformAuthCookieBasedFeatureToggle: Symbol.for("StockSurveyTypeformAuthCookieBasedFeatureToggle"),
  AutomaticSelectionTypeformAuthCookieBasedFeatureToggle: Symbol.for(
    "AutomaticSelectionTypeformAuthCookieBasedFeatureToggle",
  ),
  NoteAssistantAuthCookieBasedFeatureToggle: Symbol.for("NoteAssistantAuthCookieBasedFeatureToggle"),
  NoteAssistantSurveyTypeformAuthCookieBasedFeatureToggle: Symbol.for(
    "NoteAssistantSurveyTypeformAuthCookieBasedFeatureToggle",
  ),
  CustomerInsightsAuthCookieBasedFeatureToggle: Symbol.for("CustomerInsightsAuthCookieBasedFeatureToggle"),
  PlanningRouteFirewall: Symbol.for("PlanningRouteFirewall"),
  CapacityRouteFirewall: Symbol.for("CapacityRouteFirewall"),
  ToLegacy: Symbol.for("ToLegacy"),

  Selections: Symbol.for("Selections"),
  SelectProductVariantHandler: Symbol.for("SelectProductVariantHandler"),
  EmitProductVariantSelectedEvent: Symbol.for("EmitProductVariantSelectedEvent"),
  DeselectProductVariantHandler: Symbol.for("DeselectProductVariantHandler"),
  EmitProductVariantDeselectedEvent: Symbol.for("EmitProductVariantDeselectedEvent"),
  ReplaceProductVariantHandler: Symbol.for("ReplaceProductVariantHandler"),
  EmitProductVariantReplacedEvent: Symbol.for("EmitProductVariantReplacedEvent"),
  MarkProductVariantAsCandidateHandler: Symbol.for("MarkProductVariantAsCandidateHandler"),
  UnmarkProductVariantAsCandidateHandler: Symbol.for("UnmarkProductVariantAsCandidateHandler"),

  SetLooksWhenProductVariantDeselected: Symbol.for("SetLooksWhenProductVariantDeselected"),
  SetLooksWhenProductVariantReplaced: Symbol.for("SetLooksWhenProductVariantReplaced"),
  SetLooksWhenBoxPreviewSent: Symbol.for("SetLooksWhenBoxPreviewSent"),

  SelectionOrdersLocalStorage: Symbol.for("SelectionOrdersLocalStorage"),
  SelectionOrders: Symbol.for("SelectionOrders"),
  SortSelectionHandler: Symbol.for("SortSelectionHandler"),
  RemoveSelectionOrderHandler: Symbol.for("RemoveSelectionOrderHandler"),
  RemoveSelectionOrderWhenBoxClosed: Symbol.for("RemoveSelectionOrderWhenBoxClosed"),
  SortSelectionWhenProductVariantSelected: Symbol.for("SortSelectionWhenProductVariantSelected"),
  SortSelectionWhenProductVariantDeselected: Symbol.for("SortSelectionWhenProductVariantDeselected"),
  SortSelectionWhenProductVariantReplaced: Symbol.for("SortSelectionWhenProductVariantReplaced"),

  Boxes: Symbol.for("Boxes"),
  CloseBoxHandler: Symbol.for("CloseBoxHandler"),
  SendBoxToSuperviseHandler: Symbol.for("SendBoxToSuperviseHandler"),

  BoxPreviews: Symbol.for("BoxPreviews"),
  SendBoxPreviewHandler: Symbol.for("SendBoxPreviewHandler"),

  LooksLocalStorage: Symbol.for("LooksLocalStorage"),
  Looks: Symbol.for("Looks"),
  SetLooksHandler: Symbol.for("SetLooksHandler"),
  PrefetchMediaWhenBoxPreviewSent: Symbol.for("PrefetchMediaWhenBoxPreviewSent"),

  CreateNotificationWhenBoxPreviewSent: Symbol.for("CreateNotificationWhenBoxPreviewSent"),
  CreateNotificationWhenSelectionNotModifiable: Symbol.for("CreateNotificationWhenSelectionNotModifiable"),

  PendingBoxView: Symbol.for("PendingBoxView"),
};

export { TYPES };
