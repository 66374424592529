import { FC, useCallback } from "react";
import classNames from "classnames";
import AspectRatio from "../../_common/AspectRatio";
import AspectRatioContainer from "../../layouts/aspectRatioContainer/AspectRatioContainer";
import { useLogger } from "../../../../logging/useLogger";
import "./picture.css";

type BasicPictureProps = {
  readonly className?: string;
  readonly src?: string;
  readonly label?: string;
  readonly aspectRatio?: AspectRatio;
  readonly lazy?: boolean;
};
const BasicPicture: FC<BasicPictureProps> = ({
  className,
  src,
  label,
  aspectRatio = AspectRatio.R_4_3,
  lazy = false,
}: BasicPictureProps) => {
  const logger = useLogger();
  const handleOnError = useCallback(() => {
    const error = new Error(`Error on load ${src} image`);
    logger.captureException(error);
  }, [logger, src]);

  return (
    <AspectRatioContainer
      aspectRatio={aspectRatio}
      className={classNames("picture picture--loaded", `picture--${aspectRatio}`, className)}
    >
      <picture className="picture__picture">
        <img
          alt={label}
          aria-label={label}
          className="picture__image"
          draggable="false"
          loading={lazy ? "lazy" : undefined}
          src={src}
          onError={handleOnError}
        />
      </picture>
    </AspectRatioContainer>
  );
};

export default BasicPicture;
