import { ProductProjection } from "../product/product";
import { ProductVariantProjection } from "../product/productVariant";

interface SelectionProductVariant extends ProductVariantProjection {
  readonly isSelected: boolean;
  readonly isCandidate: boolean;
  readonly hasIssue: boolean;
}

type SelectionProduct = ProductProjection<SelectionProductVariant>;

interface Selection {
  readonly selectionId: string;
  readonly boxId: string;
  readonly products: SelectionProduct[];
  readonly automaticSelectionStartedOn: Date | null;
  readonly automaticSelectionFinishedOn: Date | null;
}

const isProductVariantIdIncludedInSelection = (selection: Selection | undefined, productVariantId: string): boolean =>
  selection?.products.some((selectionProduct) =>
    selectionProduct.productVariants.some(
      (selectionProductVariant) =>
        selectionProductVariant.isSelected && selectionProductVariant.id === productVariantId,
    ),
  ) || false;

export { isProductVariantIdIncludedInSelection };

export type {
  Selection as SelectionProjection,
  SelectionProduct as SelectionProductProjection,
  SelectionProductVariant as SelectionProductVariantProjection,
};
