import boxPreviewFinalStatuses from "../../boxPreview/model/BoxPreviewFinalStatuses";
import BoxPreviewStatus from "../../boxPreview/model/BoxPreviewStatus";
import ProductVariant from "./ProductVariant";
import SelectionNotModifiableError from "./SelectionNotModifiableError";

type SelectParameters = {
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
  readonly automaticSelectionStartedOn: Date | null;
  readonly automaticSelectionFinishedOn: Date | null;
  readonly productVariantId: string;
};

type DeselectParameters = {
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
  readonly automaticSelectionStartedOn: Date | null;
  readonly automaticSelectionFinishedOn: Date | null;
  readonly productVariantId: string;
};

type ReplaceParameters = {
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
  readonly automaticSelectionStartedOn: Date | null;
  readonly automaticSelectionFinishedOn: Date | null;
  readonly selectedProductVariantId: string;
  readonly deselectedProductVariantId: string;
};

class SelectionProductVariants {
  public productVariants: ProductVariant[];

  public constructor(productVariants: ProductVariant[]) {
    this.productVariants = productVariants;
  }

  public select({
    boxPreviewStatus,
    automaticSelectionStartedOn,
    automaticSelectionFinishedOn,
    productVariantId,
  }: SelectParameters): void {
    this.isSelectionModifiable(boxPreviewStatus, automaticSelectionStartedOn, automaticSelectionFinishedOn);
    this.isProductVariantAlreadySelected(productVariantId, this.productVariants);

    this.productVariants = this.add(productVariantId, this.productVariants);
  }

  public deselect({
    boxPreviewStatus,
    automaticSelectionStartedOn,
    automaticSelectionFinishedOn,
    productVariantId,
  }: DeselectParameters): void {
    this.isSelectionModifiable(boxPreviewStatus, automaticSelectionStartedOn, automaticSelectionFinishedOn);
    this.isProductVariantNotSelected(productVariantId, this.productVariants);

    this.productVariants = this.remove(productVariantId, this.productVariants);
  }

  public replace({
    boxPreviewStatus,
    automaticSelectionStartedOn,
    automaticSelectionFinishedOn,
    selectedProductVariantId,
    deselectedProductVariantId,
  }: ReplaceParameters): void {
    this.isSelectionModifiable(boxPreviewStatus, automaticSelectionStartedOn, automaticSelectionFinishedOn);
    this.isProductVariantNotSelected(deselectedProductVariantId, this.productVariants);
    this.isProductVariantAlreadySelected(selectedProductVariantId, this.productVariants);

    this.productVariants = this.add(
      selectedProductVariantId,
      this.remove(deselectedProductVariantId, this.productVariants),
    );
  }

  public markAsCandidate(productVariantId: string): void {
    this.isProductVariantNotSelected(productVariantId, this.productVariants);

    const productVariant = this.productVariantById(productVariantId);
    productVariant.markAsCandidate();
  }

  public unmarkAsCandidate(productVariantId: string): void {
    this.isProductVariantNotSelected(productVariantId, this.productVariants);

    const productVariant = this.productVariantById(productVariantId);
    productVariant.unmarkAsCandidate();
  }

  private isSelectionModifiable(
    boxPreviewStatus: BoxPreviewStatus | undefined,
    automaticSelectionStartedOn: Date | null,
    automaticSelectionFinishedOn: Date | null,
  ): void | never {
    if (boxPreviewStatus && !boxPreviewFinalStatuses.includes(boxPreviewStatus)) {
      throw new SelectionNotModifiableError();
    }

    if (automaticSelectionStartedOn !== null && automaticSelectionFinishedOn === null) {
      throw new SelectionNotModifiableError();
    }
  }

  private isProductVariantAlreadySelected(productVariantId: string, productVariants: ProductVariant[]): void | never {
    if (productVariants.find((selectionProductVariant) => selectionProductVariant.id === productVariantId)) {
      throw new Error("Product variant already exists in the Selection");
    }
  }

  private isProductVariantNotSelected(productVariantId: string, productVariants: ProductVariant[]): void | never {
    if (!productVariants.find((selectionProductVariant) => selectionProductVariant.id === productVariantId)) {
      throw new Error("Product variant does not exist in the Selection");
    }
  }

  private add(productVariantId: string, productVariants: ProductVariant[]): ProductVariant[] {
    return [...productVariants, new ProductVariant(productVariantId)];
  }

  private remove(productVariantId: string, productVariants: ProductVariant[]): ProductVariant[] {
    return productVariants.filter((selectionProductVariant) => selectionProductVariant.id !== productVariantId);
  }

  private productVariantById(productVariantId: string): ProductVariant | never {
    const productVariant = this.productVariants.find(
      (selectionProductVariant) => selectionProductVariant.id === productVariantId,
    );

    if (!productVariant) {
      throw new Error("Product variant does not exist in the Selection");
    }

    return productVariant;
  }

  public productVariantIds(): string[] {
    return this.productVariants.map((productVariant) => productVariant.id);
  }
}

export default SelectionProductVariants;
