type BoxByLegacyBoxIdRequestPayload = {
  readonly legacy_id: string;
};
const boxByLegacyBoxIdRequestPayload = (legacyBoxId: string): BoxByLegacyBoxIdRequestPayload => ({
  legacy_id: legacyBoxId,
});

type CloseBoxRequestPayload = {
  readonly box_id: string;
};
const closeBoxRequestPayload = (boxId: string): CloseBoxRequestPayload => ({
  box_id: boxId,
});

type SendBoxToSuperviseRequestPayload = {
  readonly box_id: string;
  readonly logged_in_ps_id: string;
};
type SendBoxToSuperviseRequestPayloadParameters = {
  readonly boxId: string;
  readonly personalShopperId: string;
};
const sendBoxToSuperviseRequestPayload = ({
  boxId,
  personalShopperId,
}: SendBoxToSuperviseRequestPayloadParameters): SendBoxToSuperviseRequestPayload => ({
  box_id: boxId,
  logged_in_ps_id: personalShopperId,
});

export { boxByLegacyBoxIdRequestPayload, closeBoxRequestPayload, sendBoxToSuperviseRequestPayload };
