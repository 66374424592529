import { useIsAutomaticSelectionEnabled } from "../../../../core/infrastructure/projection/selection/react/useIsAutomaticSelectionEnabled";
import { useViewUiSettingByKey } from "../../../../core/infrastructure/projection/uiSetting/react/useViewUiSettingByKey";
import useIsFeatureToggleEnabled from "../../../../shared/featureToggle/ui/useIsFeatureToggleEnabled";
import FeatureToggles from "../../../_featureToggle/FeatureToggles";
import useFeatureToggle from "../../../_featureToggle/useFeatureToggle";
import { AutomaticSelectionStatus, useAutomaticSelection } from "../../_behaviors/useAutomaticSelection";

interface UseIsAutomaticSelectionTypeformActiveFunctionArgs {
  readonly personalShopperId?: string;
  readonly uiSettingKey: string;
}

interface UseIsAutomaticSelectionTypeformActiveFunction {
  (args: UseIsAutomaticSelectionTypeformActiveFunctionArgs): boolean;
}

const useIsAutomaticSelectionTypeformActive: UseIsAutomaticSelectionTypeformActiveFunction = ({
  personalShopperId,
  uiSettingKey,
}) => {
  const [automaticSelectionEnabled] = useIsAutomaticSelectionEnabled({ psId: personalShopperId });
  const { automaticSelectionStatus } = useAutomaticSelection();

  const automaticSelectionTypeformFeatureToggle = useFeatureToggle(FeatureToggles.AUTOMATIC_SELECTION_TYPEFORM);
  const automaticSelectionTypeformFeatureToggleEnabled = useIsFeatureToggleEnabled({
    featureToggle: automaticSelectionTypeformFeatureToggle,
  });

  const [uiSetting] = useViewUiSettingByKey({ key: uiSettingKey });

  return Boolean(
    !uiSetting &&
      automaticSelectionEnabled &&
      automaticSelectionTypeformFeatureToggleEnabled &&
      automaticSelectionStatus === AutomaticSelectionStatus.FINISHED,
  );
};

export { useIsAutomaticSelectionTypeformActive };
