import { Command } from "@lookiero/messaging.js";
import LookDTO from "../../model/LookDTO";

class SetLooks extends Command {
  public readonly selectionId: string;
  public readonly looks: LookDTO[];

  public constructor(selectionId: string, looks: LookDTO[]) {
    super();
    this.selectionId = selectionId;
    this.looks = looks;
  }

  protected messageName(): string {
    return "SetLooks";
  }
}

export default SetLooks;
