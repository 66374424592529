import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import BoxClosed from "../../box/event/BoxClosed";
import RemoveSelectionOrder from "../command/removeSelectionOrder/RemoveSelectionOrder";

class RemoveSelectionOrderWhenBoxClosed implements ProcessManager<BoxClosed> {
  public readonly commandBus!: CommandBus;

  public async process({ legacyBoxId }: BoxClosed): Promise<void> {
    this.commandBus.dispatch(new RemoveSelectionOrder(legacyBoxId));
  }
}

export default RemoveSelectionOrderWhenBoxClosed;
