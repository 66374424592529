import { EventBus } from "@lookiero/messaging.js";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import ProductVariantDeselected from "../../../../domain/selection/event/ProductVariantDeselected";
import ProductVariantReplaced from "../../../../domain/selection/event/ProductVariantReplaced";
import ProductVariantSelected from "../../../../domain/selection/event/ProductVariantSelected";
import Selection from "../../../../domain/selection/model/Selection";
import Selections from "../../../../domain/selection/model/Selections";
import {
  deselectProductVariantRequestPayload,
  replaceProductVariantRequestPayload,
  selectProductVariantRequestPayload,
  viewSelectionByLegacyBoxIdRequestPayload,
  markProductVariantAsCandidateRequestPayload,
  unmarkProductVariantAsCandidateRequestPayload,
} from "./requestBuilder";
import { selectionFromResponse } from "./responseMapper";
import { camelcaseKeys } from "../../../../../shared/delivery/infrastructure/camelcaseKeys";

class HttpSelections implements Selections {
  private readonly httpClient: HttpClient;
  private readonly eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async update(selection: Selection): Promise<void> {
    this.eventBus.publish(selection.recordedEvents());
  }

  public async byLegacyBoxId(legacyBoxId: string): Promise<Selection | undefined> {
    try {
      const requestPayload = viewSelectionByLegacyBoxIdRequestPayload(legacyBoxId);

      const response = await this.httpClient.post("/view-selection-by-legacy-box-id", requestPayload);

      if (!response.ok) {
        if (response.status === 404) {
          return undefined;
        }

        throw new Error("View Selection by Legacy Box Id");
      }

      const { result: selectionResponse } = await response.json();

      const selection: Selection = selectionFromResponse(legacyBoxId, camelcaseKeys(selectionResponse));

      return selection;
    } catch (error) {
      console.error(error);
      throw new Error("View Selection by Legacy Box Id");
    }
  }

  public async selectProductVariant(selection: Selection, productVariantId: string): Promise<void> {
    try {
      const recordedEvents = selection.recordedEvents();

      if (recordedEvents.some((recordedEvent) => recordedEvent instanceof ProductVariantSelected)) {
        const requestPayload = selectProductVariantRequestPayload(
          selection.selectionId,
          selection.boxId,
          productVariantId,
        );

        const response = await this.httpClient.post("/select-product-variant", requestPayload);

        if (!response.ok) {
          throw new Error("Select product variant");
        }
      }

      this.eventBus.publish(recordedEvents);
    } catch (error) {
      console.error(error);
      throw new Error("Select product variant");
    }
  }

  public async deselectProductVariant(selection: Selection, productVariantId: string): Promise<void> {
    try {
      const recordedEvents = selection.recordedEvents();

      if (recordedEvents.some((recordedEvent) => recordedEvent instanceof ProductVariantDeselected)) {
        const requestPayload = deselectProductVariantRequestPayload(selection.selectionId, productVariantId);

        const response = await this.httpClient.post("/deselect-product-variant", requestPayload);

        if (!response.ok) {
          throw new Error("Deselect product variant");
        }
      }

      this.eventBus.publish(recordedEvents);
    } catch (error) {
      console.error(error);
      throw new Error("Deselect product variant");
    }
  }

  public async replaceProductVariant(
    selection: Selection,
    selectProductVariantId: string,
    deselectProductVariantId: string,
  ): Promise<void> {
    try {
      const recordedEvents = selection.recordedEvents();

      if (recordedEvents.some((recordedEvent) => recordedEvent instanceof ProductVariantReplaced)) {
        const requestPayload = replaceProductVariantRequestPayload(
          selection.boxId,
          selection.selectionId,
          selectProductVariantId,
          deselectProductVariantId,
        );

        const response = await this.httpClient.post("/replace-product-variant", requestPayload);

        if (!response.ok) {
          throw new Error("Replace product variant");
        }
      }

      this.eventBus.publish(recordedEvents);
    } catch (error) {
      console.error(error);
      throw new Error("Replace product variant");
    }
  }

  public async markProductVariantAsCandidate(selection: Selection, productVariantId: string): Promise<void> {
    try {
      const requestPayload = markProductVariantAsCandidateRequestPayload(selection.selectionId, productVariantId);

      const response = await this.httpClient.post("/mark-product-variant-as-candidate", requestPayload);

      if (!response.ok) {
        throw new Error("Mark as candidate product variant");
      }

      this.eventBus.publish(selection.recordedEvents());
    } catch (error) {
      console.error(error);
      throw new Error("Mark as candidate product variant");
    }
  }

  public async unmarkProductVariantAsCandidate(selection: Selection, productVariantId: string): Promise<void> {
    try {
      const requestPayload = unmarkProductVariantAsCandidateRequestPayload(selection.selectionId, productVariantId);

      const response = await this.httpClient.post("/unmark-product-variant-as-candidate", requestPayload);

      if (!response.ok) {
        throw new Error("Unmark as candidate product variant");
      }

      this.eventBus.publish(selection.recordedEvents());
    } catch (error) {
      console.error(error);
      throw new Error("Unmark as candidate product variant");
    }
  }
}

export default HttpSelections;
