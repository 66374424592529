import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import { QueryBus as MessagingQueryBus } from "@lookiero/messaging";
import SortSelection from "../command/sortSelection/SortSelection";
import ProductVariantReplaced from "../../selection/event/ProductVariantReplaced";
import {
  ViewSelectionOrderByBoxNumberResult,
  viewSelectionOrderByBoxNumber,
} from "../../../projection/selectionOrder/viewSelectionOrderByBoxNumber";

class SortSelectionWhenProductVariantReplaced implements ProcessManager<ProductVariantReplaced> {
  private readonly commandBus!: CommandBus;
  private readonly messagingQueryBus!: MessagingQueryBus;

  public async process({
    legacyBoxId,
    selectionId,
    selectedProductVariantId,
    deselectedProductVariantId,
    productVariantIds,
  }: ProductVariantReplaced): Promise<void> {
    const selectionOrder: ViewSelectionOrderByBoxNumberResult = await this.messagingQueryBus(
      viewSelectionOrderByBoxNumber({ boxNumber: legacyBoxId }),
    );

    this.commandBus.dispatch(
      new SortSelection({
        legacyBoxId,
        selectionId,
        productVariantIds: selectionOrder
          ? selectionOrder.productVariantIds.map((productVariantId: string) =>
              productVariantId === deselectedProductVariantId ? selectedProductVariantId : productVariantId,
            )
          : productVariantIds,
      }),
    );
  }
}

export default SortSelectionWhenProductVariantReplaced;
