import { v4 as uuid } from "uuid";
import { ProcessManager } from "@lookiero/messaging.js";
import SelectionNotModifiable, { SelectionNotModifiableActions } from "./SelectionNotModifiable";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../../ui/componentLibrary/_i18n/SelectionI18n";
import { createToastNotification } from "../../../../shared/notifications/domain/notification/command/createToastNotification";
import { CommandBus as MessagingCommandBus } from "@lookiero/messaging";
import { NotificationLevel } from "../../../../shared/notifications/domain/notification/model/notification";

const selectionNotModifiableActionsNotification = {
  [SelectionNotModifiableActions.SELECT]: SelectionI18n.CAN_NOT_SELECT_PRODUCT_VARIANT_NOTIFICATION,
  [SelectionNotModifiableActions.DESELECT]: SelectionI18n.CAN_NOT_DESELECT_PRODUCT_VARIANT_NOTIFICATION,
  [SelectionNotModifiableActions.REPLACE]: SelectionI18n.CAN_NOT_REPLACE_PRODUCT_VARIANT_NOTIFICATION,
};

class CreateNotificationWhenSelectionNotModifiable implements ProcessManager<SelectionNotModifiable> {
  private readonly messagingCommandBus!: MessagingCommandBus;

  public async process({ action }: SelectionNotModifiable): Promise<void> {
    this.messagingCommandBus(
      createToastNotification({
        aggregateId: uuid(),
        bodyI18nKey: `${SELECTION_I18N_PREFIX}${selectionNotModifiableActionsNotification[action]}`,
        level: NotificationLevel.ERROR,
      }),
    );
  }
}

export default CreateNotificationWhenSelectionNotModifiable;
