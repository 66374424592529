import { AggregateRoot } from "@lookiero/messaging.js";
import SelectionSorted from "../event/SelectionSorted";

type SelectionOrderParameters = {
  readonly legacyBoxId: string;
  readonly selectionId: string;
  readonly productVariantIds: string[];
};

class SelectionOrder extends AggregateRoot {
  public readonly legacyBoxId: string;
  public readonly selectionId: string;
  public productVariantIds: string[];

  public constructor({ legacyBoxId, selectionId, productVariantIds }: SelectionOrderParameters) {
    super();

    this.legacyBoxId = legacyBoxId;
    this.selectionId = selectionId;
    this.productVariantIds = productVariantIds;
  }

  public static sort({ legacyBoxId, selectionId, productVariantIds }: SelectionOrderParameters): SelectionOrder {
    const instance = new SelectionOrder({ legacyBoxId, selectionId, productVariantIds });

    instance.record(new SelectionSorted(instance.legacyBoxId));

    return instance;
  }

  public sort(productVariantIds: string[]): void {
    this.productVariantIds = productVariantIds;

    this.record(new SelectionSorted(this.legacyBoxId));
  }
}

export default SelectionOrder;
