import { DomainEvent } from "@lookiero/messaging.js";
import Class from "@lookiero/messaging.js/dist/Class";
import BoxClosed from "../../domain/box/event/BoxClosed";
import BoxSentToSupervise from "../../domain/box/event/BoxSentToSupervise";
import BoxPreviewSent from "../../domain/boxPreview/event/BoxPreviewSent";
import ProductVariantDeselected from "../../domain/selection/event/ProductVariantDeselected";
import ProductVariantReplaced from "../../domain/selection/event/ProductVariantReplaced";
import ProductVariantSelected from "../../domain/selection/event/ProductVariantSelected";

const integrationEventRegistry: Record<string, Class<DomainEvent>> = {
  BoxClosed,
  BoxSentToSupervise,
  BoxPreviewSent,
  ProductVariantDeselected,
  ProductVariantReplaced,
  ProductVariantSelected,
};

type IntegrationEvent = {
  readonly name: string;
  readonly [x: string]: unknown;
};

export { integrationEventRegistry };

export default IntegrationEvent;
