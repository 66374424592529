import { SelectionOrderProjection } from "../../../projection/selectionOrder/selectionOrder";
import { SelectionOrderByBoxNumberView } from "../../../projection/selectionOrder/viewSelectionOrderByBoxNumber";
import { ReadFunction } from "../../persistence/storage";

interface StorageSelectionOrderByBoxNumberView extends SelectionOrderByBoxNumberView {}

interface StorageSelectionOrderByBoxNumberViewFunctionArgs {
  readonly read: ReadFunction<SelectionOrderProjection>;
}

interface StorageSelectionOrderByBoxNumberViewFunction {
  (args: StorageSelectionOrderByBoxNumberViewFunctionArgs): StorageSelectionOrderByBoxNumberView;
}

const storageSelectionOrderByBoxNumberView: StorageSelectionOrderByBoxNumberViewFunction =
  ({ read }) =>
  async ({ boxNumber }) => {
    try {
      return await read(boxNumber);
    } catch (ignored) {
      throw new Error("Could not fetch selectionOrder");
    }
  };

export { storageSelectionOrderByBoxNumberView };
