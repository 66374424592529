import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Routes } from "../../../_routing/Routing";
import { useNavigate, useLocation } from "react-router-dom";
import ProductPreview from "../../../componentLibrary/organisms/productPreview/ProductPreview";
import useSelectProductVariant from "../../_behaviors/useSelectProductVariant";
import useProductVariantComparer from "../../_behaviors/useProductVariantComparer";
import useBoxSharedContext from "../../_behaviors/useBoxSharedContext";
import ProductPreviewActionButton from "../../../componentLibrary/molecules/productPreviewActionButton/ProductPreviewActionButton";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import useProductVariantSelection from "../../_behaviors/useProductVariantSelection";
import useFavourites from "../../_behaviors/useFavourites";
import { Subsection, TrackingClick, TrackingPage } from "../../../../shared/tracking/Tracker";
import { useTrackClick } from "../../../../shared/tracking/infrastructure/useTrackClick";
import { useTrackSelectDeselectProductVariant } from "../../../../shared/tracking/infrastructure/useTrackSelectDeselectProductVariant";
import {
  ShortlistProductProjection,
  ShortlistProductVariantProjection,
} from "../../../../core/projection/shortlist/shortlistProduct";
import { generatePath } from "react-router-dom";
import { useViewPersonalShopper } from "../../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { useViewBoxPreviewByBoxId } from "../../../../core/infrastructure/projection/boxPreview/react/useViewBoxPreviewByBoxId";
import { ProductVariantProjection } from "../../../../core/projection/product/productVariant";
import { isProductVariantIdIncludedInSelection } from "../../../../core/projection/selection/selection";
import "./shortlist-product-preview.css";

const matchingFiltersProductVariants = (
  productVariants: ShortlistProductVariantProjection[],
): ShortlistProductVariantProjection[] => productVariants.filter((productVariant) => productVariant.matchesFilters);

type ShortlistProductPreviewProps = {
  readonly product: ShortlistProductProjection;
  readonly locale: string;
  readonly position?: number;
};
const ShortlistProductPreview: FC<ShortlistProductPreviewProps> = ({
  product,
  locale,
  position = 0,
}: ShortlistProductPreviewProps) => {
  const { box, selection } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();
  const { add: addProductVariantToComparer } = useProductVariantComparer();
  const [boxPreview] = useViewBoxPreviewByBoxId({ boxId: box.id });
  const { state: selectProductVariantState, selectProductVariant } = useSelectProductVariant({
    legacyBoxId: String(box.legacyId),
    boxId: box.id,
    selectionId: selection?.selectionId,
    boxPreviewStatus: boxPreview?.status,
  });
  const {
    isFavourite,
    markProductAsFavouriteState,
    markProductAsFavourite,
    unmarkProductAsFavouriteState,
    unmarkProductAsFavourite,
  } = useFavourites({ product, section: TrackingPage.CATALOG, position });

  const trackClick = useTrackClick({
    boxId: box.id,
    psId: personalShopper?.id,
    section: TrackingPage.CATALOG,
    userId: box.customerId,
  });
  const trackSelectDeselectProductVariant = useTrackSelectDeselectProductVariant({
    boxId: box.id,
    psId: personalShopper?.id,
    section: TrackingPage.CATALOG,
    subsection: Subsection.CATALOG,
    userId: box.customerId,
  });

  const { search } = useLocation();
  const searchRef = useRef(search);
  searchRef.current = search;
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  navigateRef.current = navigate;

  const handleProductPreviewClick = useCallback(() => {
    trackClick({
      clickId: TrackingClick.ITEM,
      position,
      productId: product.id,
    });

    const productLink = `${generatePath(Routes.BOX_SHORTLIST_PRODUCT, {
      locale,
      product: product.id,
      box: String(box.legacyId),
    })}${searchRef.current}`;

    navigateRef.current(productLink);
  }, [box.legacyId, locale, position, product.id, trackClick]);

  const selectionProductVariant = product.productVariants.find((productVariant: ProductVariantProjection) =>
    isProductVariantIdIncludedInSelection(selection, productVariant.id),
  );
  const [productVariant, setProductVariant] = useProductVariantSelection({
    selectionProductVariant,
    product,
    filter: matchingFiltersProductVariants,
  });

  /**
   * This cleanup effect is required in order to 'reset' this product's associated productVariantSelection,
   * as it will be only unmounted if the shortlistFilters has changed.
   */
  useEffect(() => () => setProductVariant(undefined), [setProductVariant]);

  const handleOnAddToComparer = useCallback(
    () => addProductVariantToComparer(product.id, productVariant),
    [addProductVariantToComparer, product.id, productVariant],
  );
  const handleOnSelectProductVariant = useCallback(() => {
    selectProductVariant(productVariant);
    trackSelectDeselectProductVariant({
      position,
      productVariantId: productVariant.id,
      select: true,
    });
  }, [position, productVariant, selectProductVariant, trackSelectDeselectProductVariant]);
  const actions = useMemo(
    () => (
      <>
        <div>
          {isFavourite ? (
            <ProductPreviewActionButton
              icon={IconVariant.HEART_FILLED}
              label="unmark-product-as-favourite"
              state={unmarkProductAsFavouriteState}
              onClick={unmarkProductAsFavourite}
            />
          ) : (
            <ProductPreviewActionButton
              icon={IconVariant.HEART}
              label="mark-product-as-favourite"
              state={markProductAsFavouriteState}
              onClick={markProductAsFavourite}
            />
          )}
          <ProductPreviewActionButton
            icon={IconVariant.COMPARE}
            label="add-to-comparer"
            onClick={handleOnAddToComparer}
          />
        </div>
        <ProductPreviewActionButton
          icon={IconVariant.BASKET}
          label="select-product-variant"
          state={selectProductVariantState}
          onClick={handleOnSelectProductVariant}
        />
      </>
    ),
    [
      handleOnAddToComparer,
      handleOnSelectProductVariant,
      isFavourite,
      markProductAsFavourite,
      markProductAsFavouriteState,
      selectProductVariantState,
      unmarkProductAsFavourite,
      unmarkProductAsFavouriteState,
    ],
  );

  return useMemo(
    () => (
      <div
        aria-label="shortlist-product-preview"
        className="shortlist-product-preview"
        role="listitem"
        onClick={handleProductPreviewClick}
      >
        <ProductPreview
          actions={actions}
          disabled={Boolean(selectionProductVariant)}
          product={product}
          productVariant={productVariant}
          onProductVariantChanged={setProductVariant}
        />
      </div>
    ),
    [handleProductPreviewClick, product, productVariant, setProductVariant, selectionProductVariant, actions],
  );
};

export { matchingFiltersProductVariants };
export default ShortlistProductPreview;
