import { CommandHandler } from "@lookiero/messaging.js";
import Selection from "../../model/Selection";
import Selections from "../../model/Selections";
import DeselectProductVariant from "./DeselectProductVariant";

class DeselectProductVariantHandler implements CommandHandler<DeselectProductVariant> {
  private readonly selections: Selections;

  public constructor(selections: Selections) {
    this.selections = selections;
  }

  public async execute({ legacyBoxId, productVariantId, boxPreviewStatus }: DeselectProductVariant): Promise<void> {
    const selection: Selection | undefined = await this.selections.byLegacyBoxId(legacyBoxId);

    if (!selection) {
      throw new Error(`Selection for the provided legacy-box-id does not exist: ${legacyBoxId}`);
    }

    selection.deselectProductVariant({
      productVariantId,
      boxPreviewStatus,
    });

    await this.selections.deselectProductVariant(selection, productVariantId);
  }
}

export default DeselectProductVariantHandler;
