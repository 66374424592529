import { bootstrap as messagingBootstrap, BuildBootstrapFunctionReturn } from "@lookiero/messaging-react";
import { fetchHttpGet, fetchHttpPost } from "../infrastructure/delivery/http/fetchHttpClient";
import { httpPlannedBoxesByCriteriaView } from "../infrastructure/projection/plannedBox/httpPlannedBoxesByCriteriaView";
import {
  searchPlannedBoxesByCriteriaHandler,
  SEARCH_PLANNED_BOXES_BY_CRITERIA,
} from "../projection/plannedBox/searchPlannedBoxesByCriteria";
import {
  VIEW_PERSONAL_SHOPPER_FILTERING_BY_ID,
  viewPersonalShopperFilteringByIdHandler,
} from "../projection/personalShopperFiltering/viewPersonalShopperFilteringById";
import { httpPersonalShopperFilteringByIdView } from "../infrastructure/projection/personalShopperFiltering/model/httpPersonalShopperFilteringByIdView";
import { SET_PERSONAL_SHOPPER_FILTERING } from "../domain/personalShopperFiltering/command/setPersonalShopperFiltering";
import { setPersonalShopperFilteringHandler } from "../domain/personalShopperFiltering/model/personalShopperFiltering";
import {
  getPersonalShopperFiltering,
  savePersonalShopperFiltering,
} from "../infrastructure/domain/personalShopperFiltering/model/httpPersonalShopperFilterings";
import { httpFilteringByCustomerIdView } from "../infrastructure/projection/filtering/model/httpFilteringByCustomerIdView";
import {
  VIEW_FILTERING_BY_CUSTOMER_ID,
  viewFilteringByCustomerIdHandler,
} from "../projection/filtering/viewFilteringByCustomerId";
import { httpEditableShortlistFiltersView } from "../infrastructure/projection/editableShortlistFilter/model/httpEditableShortlistFiltersView";
import {
  LIST_EDITABLE_SHORTLIST_FILTERS,
  listEditableShortlistFiltersHandler,
} from "../projection/editableShortlistFilter/listEditableShortlistFilters";
import {
  VIEW_ANNOTATION_BY_CUSTOMER_ID,
  viewAnnotationByCustomerIdHandler,
} from "../projection/annotation/viewAnnotationByCustomerId";
import { httpAnnotationByCustomerIdView } from "../infrastructure/projection/annotation/model/httpAnnotationByCustomerIdView";
import { UPDATE_ANNOTATION } from "../domain/annotation/command/updateAnnotation";
import { updateAnnotationHandler } from "../domain/annotation/model/annotation";
import { getAnnotation, saveAnnotation } from "../infrastructure/domain/annotation/model/httpAnnotations";
import { VIEW_UI_SETTING_BY_KEY, viewUiSettingByKeyHandler } from "../projection/uiSetting/viewUiSettingByKey";
import { UPDATE_UI_SETTING } from "../domain/uiSetting/command/updateUiSetting";
import { updateUiSettingHandler } from "../domain/uiSetting/model/uiSetting";
import { getUiSetting, saveUiSetting } from "../infrastructure/domain/uiSetting/model/storageUiSettings";
import { storageUiSettingByKeyView } from "../infrastructure/projection/uiSetting/storageUiSettingByKeyView";
import {
  read as readLocalStorage,
  write as writeLocalStorage,
} from "../infrastructure/persistence/localStorageStorage";
import { read as readCookieStorage } from "../infrastructure/persistence/cookieStorage";
import { VIEW_FAVOURITE_BY_ID, viewFavouriteByIdHandler } from "../projection/favourite/viewFavouriteById";
import { httpFavouriteByIdView } from "../infrastructure/projection/favourite/httpFavouriteByIdView";
import { MARK_PRODUCT_AS_FAVOURITE } from "../domain/favourite/command/markProductAsFavourite";
import { markProductAsFavouriteHandler, unmarkProductAsFavouriteHandler } from "../domain/favourite/model/favourite";
import { getFavourite, saveFavourite } from "../infrastructure/domain/favourite/model/httpFavourites";
import {
  SEARCH_FAVOURITES_BY_PS_ID,
  searchFavouritesByPsIdHandler,
} from "../projection/favourite/searchFavouritesByPsId";
import { httpFavouritesByPsIdView } from "../infrastructure/projection/favourite/httpFavouritesByPsIdView";
import { UNMARK_PRODUCT_AS_FAVOURITE } from "../domain/favourite/command/unmarkProductAsFavourite";
import {
  COUNT_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS,
  countShortlistProductsByShortlistFiltersHandler,
} from "../projection/shortlist/countShortlistProductsByShortlistFilters";
import {
  SEARCH_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS,
  searchShortlistProductsByShortlistFiltersHandler,
} from "../projection/shortlist/searchShortlistProductsByShortlistFilters";
import { httpShortlistProductsSearchByShortlistFiltersView } from "../infrastructure/projection/shortlist/httpShortlistProductsSearchByShortlistFiltersView";
import { httpShortlistProductsCountByShortlistFiltersView } from "../infrastructure/projection/shortlist/httpShortlistProductsCountByShortlistFiltersView";
import { VIEW_NOTE_TEMPLATE_BY_ID, viewNoteTemplateByIdHandler } from "../projection/noteTemplate/viewNoteTemplateById";
import { httpNoteTemplateByIdView } from "../infrastructure/projection/noteTemplate/httpNoteTemplateByIdView";
import { WRITE_NOTE_TEMPLATE } from "../domain/noteTemplate/command/writeNoteTemplate";
import { removeNoteTemplateHandler, writeNoteTemplateHandler } from "../domain/noteTemplate/model/noteTemplate";
import { getNoteTemplate, saveNoteTemplate } from "../infrastructure/domain/noteTemplate/model/httpNoteTemplates";
import { REMOVE_NOTE_TEMPLATE } from "../domain/noteTemplate/command/removeNoteTemplate";
import {
  VALIDATE_NOTE_TEMPLATE,
  validateNoteTemplateHandler,
} from "../projection/noteTemplateValidation/validateNoteTemplate";
import {
  SEARCH_NOTE_TEMPLATES_BY_CRITERIA,
  searchNoteTemplatesByCriteriaHandler,
} from "../projection/noteTemplate/searchNoteTemplatesByCriteria";
import { httpNoteTemplatesSearchByCriteriaView } from "../infrastructure/projection/noteTemplate/httpNoteTemplatesSearchByCriteriaView";
import {
  COUNT_NOTE_TEMPLATES_BY_CRITERIA,
  countNoteTemplatesByCriteriaHandler,
} from "../projection/noteTemplate/countNoteTemplatesByCriteria";
import { httpNoteTemplatesCountByCriteriaView } from "../infrastructure/projection/noteTemplate/httpNoteTemplatesCountByCriteriaView";
import {
  LIST_NOTE_TEMPLATE_CATEGORIES_BY_PS_ID,
  listNoteTemplateCategoriesByPsIdHandler,
} from "../projection/noteTemplateCategory/listNoteTemplateCategoriesByPsId";
import { httpNoteTemplateCategoriesByPsIdView } from "../infrastructure/projection/noteTemplateCategory/httpNoteTemplateCategoriesByPsIdView";
import {
  VIEW_FORECAST_BY_CUSTOMER_ID,
  viewForecastByCustomerIdHandler,
} from "../projection/forecast/viewForecastByCustomerId";
import { httpForecastByCustomerIdView } from "../infrastructure/projection/forecast/httpForecastByCustomerIdView";
import { VIEW_ANNOTATION_BY_ID, viewAnnotationByIdHandler } from "../projection/annotation/viewAnnotationById";
import { httpAnnotationByIdView } from "../infrastructure/projection/annotation/model/httpAnnotationByIdView";
import { VIEW_NOTE_BY_BOX_ID, viewNoteByBoxIdHandler } from "../projection/note/viewNoteByBoxId";
import { httpNoteByBoxIdView } from "../infrastructure/projection/note/model/httpNoteByBoxIdView";
import { VIEW_NOTE_BY_ID, viewNoteByIdHandler } from "../projection/note/viewNoteById";
import { httpNoteByIdView } from "../infrastructure/projection/note/model/httpNoteByIdView";
import { WRITE_NOTE } from "../domain/note/command/writeNote";
import { generateNoteHandler, writeNoteHandler } from "../domain/note/model/note";
import { getNote, saveNote } from "../infrastructure/domain/note/model/httpNotes";
import { GENERATE_NOTE } from "../domain/note/command/generateNote";
import { VIEW_PERSONAL_SHOPPER, viewPersonalShopperHandler } from "../projection/personalShopper/viewPersonalShopper";
import { cookieStoragePersonalShopperView } from "../infrastructure/projection/personalShopper/model/cookieStoragePersonalShopperView";
import jwtDecode from "jwt-decode";
import {
  CAN_PERSONAL_SHOPPER_WRITE_NOTE,
  canPersonalShopperWriteNoteHandler,
} from "../projection/personalShopper/canPersonalShopperWriteNote";
import {
  CAN_PERSONAL_SHOPPER_SEND_BOXES_TO_SUPERVISE,
  canPersonalShopperSendBoxesToSuperviseHandler,
} from "../projection/personalShopper/canPersonalShopperSendBoxesToSupervise";
import { VIEW_BOX_BY_ID, viewBoxByIdHandler } from "../projection/box/viewBoxById";
import { httpBoxByIdView } from "../infrastructure/projection/box/httpBoxByIdView";
import {
  CAN_PERSONAL_SHOPPER_GENERATE_NOTE,
  canPersonalShopperGenerateNoteHandler,
} from "../projection/personalShopper/canPersonalShopperGenerateNote";
import { VIEW_CUSTOMER_BY_ID, viewCustomerByIdHandler } from "../projection/customer/viewCustomerById";
import { httpCustomerByIdView } from "../infrastructure/projection/customer/httpCustomerByIdView";
import {
  VIEW_ACTIVITY_TIME_BY_PS_ID_AND_BOX_ID,
  viewActivityTimeByPsIdAndBoxIdHandler,
} from "../projection/activityTime/viewActivityTimeByPsIdAndBoxId";
import { httpActivityTimeByPsIdAndBoxIdView } from "../infrastructure/projection/activityTime/httpActivityTimeByPsIdAndBoxIdView";
import { REGISTER_ACTIVITY_TIME } from "../domain/activityTime/command/registerActivityTime";
import {
  registerActivityTimeHandler,
  registerPreviewedWatermarkHandler,
} from "../domain/activityTime/model/activityTime";
import {
  getActivityTime,
  saveActivityTime,
} from "../infrastructure/domain/activityTime/model/httpOrNavigatorActivityTimes";
import { windowNavigatorSendBeacon } from "../infrastructure/delivery/navigator/windowNavigator";
import { REGISTER_PREVIEWED_WATERMARK } from "../domain/activityTime/command/registerPreviewedWatermark";
import { IS_THE_BOX_OPEN, isTheBoxOpenHandler } from "../projection/box/isTheBoxOpen";
import { httpIsTheBoxOpenView } from "../infrastructure/projection/box/httpIsTheBoxOpenView";
import {
  VIEW_CAPACITY_CALENDAR_BY_CRITERIA,
  viewCapacityCalendarByCriteriaHandler,
} from "../projection/capacityCalendar/viewCapacityCalendarByCriteria";
import { httpCapacityCalendarByCriteriaView } from "../infrastructure/projection/capacityCalendar/httpCapacityCalendarByCriteriaView";
import {
  COUNT_CAPACITY_CALENDAR_BY_CRITERIA,
  countCapacityCalendarByCriteriaHandler,
} from "../projection/capacityCalendar/countCapacityCalendarByCriteria";
import { httpCapacityCalendarCountByCriteriaView } from "../infrastructure/projection/capacityCalendar/httpCapacityCalendarCountByCriteriaView";
import { SYNCHRONIZE_CAPACITY_CALENDAR } from "../domain/capacityCalendar/command/synchronizeCapacityCalendar";
import { synchronizeCapacityCalendarHandler } from "../domain/capacityCalendar/model/capacityCalendar";
import {
  getCapacityCalendar,
  saveCapacityCalendar,
} from "../infrastructure/domain/capacityCalendar/model/httpCapacityCalendars";
import {
  VIEW_BOX_PREVIEW_BY_BOX_ID,
  viewBoxPreviewByBoxIdHandler,
} from "../projection/boxPreview/viewBoxPreviewByBoxId";
import { httpBoxPreviewByBoxIdView } from "../infrastructure/projection/boxPreview/httpBoxPreviewByBoxIdView";
import { VIEW_BOX_BY_BOX_NUMBER, viewBoxByBoxNumberHandler } from "../projection/box/viewBoxByBoxNumber";
import { httpBoxByBoxNumberView } from "../infrastructure/projection/box/httpBoxByBoxNumberView";
import {
  LIST_NOTE_ALERTS_BY_BOX_NUMBER,
  listNoteAlertsByBoxNumberHandler,
} from "../projection/alert/listNoteAlertsByBoxNumber";
import { httpNoteAlertsByBoxNumberView } from "../infrastructure/projection/alert/httpNoteAlertsByBoxNumberView";
import {
  LIST_SELECTION_ALERTS_BY_BOX_NUMBER,
  listSelectionAlertsByBoxNumberHandler,
} from "../projection/alert/listSelectionAlertsByBoxNumber";
import { httpSelectionAlertsByBoxNumberView } from "../infrastructure/projection/alert/httpSelectionAlertsByBoxNumberView";
import {
  CONTAINS_BLOCKING_ALERTS_BY_BOX_NUMBER,
  containsBlockingAlertsByBoxNumberHandler,
} from "../projection/alert/containsBlockingAlertsByBoxNumber";
import { LIST_FAMILIES, listFamiliesHandler } from "../projection/family/listFamilies";
import { httpFamiliesView } from "../infrastructure/projection/family/httpFamiliesView";
import {
  IS_STOCK_SURVEY_SUBMITTED_BY_BOX_ID,
  isStockSurveySubmittedByBoxIdHandler,
} from "../projection/stockSurvey/isStockSurveySubmittedByBoxId";
import { httpIsStockSurveySubmittedByBoxIdView } from "../infrastructure/projection/stockSurvey/httpIsStockSurveySubmittedByBoxIdView";
import { SUBMIT_STOCK_SURVEY } from "../domain/stockSurvey/command/submitStockSurvey";
import { submitStockSurveyHandler } from "../domain/stockSurvey/model/stockSurvey";
import { getStockSurvey, saveStockSurvey } from "../infrastructure/domain/stockSurvey/model/httpStockSurveys";
import {
  SEARCH_HISTORY_BOXES_BY_CRITERIA,
  searchHistoryBoxesByCriteriaHandler,
} from "../projection/historyBox/searchHistoryBoxesByCriteria";
import { httpHistoryBoxesByCriteriaView } from "../infrastructure/projection/historyBox/httpHistoryBoxesByCriteriaView";
import {
  LIST_HISTORY_NOTES_BY_CUSTOMER_ID,
  listHistoryNotesByCustomerIdHandler,
} from "../projection/historyNote/listHistoryNotesByCustomerId";
import { httpHistoryNotesByCustomerIdView } from "../infrastructure/projection/historyNote/httpHistoryNotesByCustomerIdView";
import {
  SEARCH_HISTORY_SHOP_BY_CUSTOMER_ID,
  searchHistoryShopByCustomerIdHandler,
} from "../projection/historyShop/searchHistoryShopByCustomerId";
import { httpHistoryShopByCustomerIdView } from "../infrastructure/projection/historyShop/httpHistoryShopByCustomerIdView";
import { LIST_LOOKS_BY_SELECTION_ID, listLooksBySelectionIdHandler } from "../projection/looks/listLooksBySelectionId";
import { storageLooksBySelectionIdView } from "../infrastructure/projection/looks/storageLooksBySelectionIdView";
import { ReadFunction } from "../infrastructure/persistence/storage";
import { LookProjection } from "../projection/looks/look";
import { VIEW_PENDING_BOXES, viewPendingBoxesHandler } from "../projection/pendingBox/viewPendingBoxes";
import { httpPendingBoxesView } from "../infrastructure/projection/pendingBox/httpPendingBoxesView";
import { LIST_BOXES_BY_CUSTOMER_ID, listBoxesByCustomerIdHandler } from "../projection/box/listBoxesByCustomerId";
import { httpBoxesByCustomerIdView } from "../infrastructure/projection/box/httpBoxesByCustomerIdView";
import { LIST_RETURN_QUESTIONS, listReturnQuestionsHandler } from "../projection/returnQuestion/listReturnQuestions";
import { httpReturnQuestionsView } from "../infrastructure/projection/returnQuestion/httpReturnQuestionsView";
import {
  LIST_LOOK_VALIDATION_ERRORS,
  listLookValidationErrorsHandler,
} from "../projection/lookValidation/listLookValidationErrors";
import {
  SEARCH_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA,
  searchLookAndLikeFeedbacksByCriteriaHandler,
} from "../projection/lookAndLikeFeedback/searchLookAndLikeFeedbacksByCriteria";
import { httpLookAndLikeFeedbacksSearchByCriteriaView } from "../infrastructure/projection/lookAndLikeFeedback/httpLookAndLikeFeedbacksSearchByCriteriaView";
import {
  COUNT_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA,
  countLookAndLikeFeedbacksByCriteriaHandler,
} from "../projection/lookAndLikeFeedback/countLookAndLikeFeedbacksByCriteria";
import { httpLookAndLikeFeedbacksCountByCriteriaView } from "../infrastructure/projection/lookAndLikeFeedback/httpLookAndLikeFeedbacksCountByCriteriaView";
import {
  LIST_PERSONAL_SHOPPER_COUNTRIES,
  listPersonalShopperCountriesHandler,
} from "../projection/personalShopperCountry/listPersonalShopperCountries";
import { httpPersonalShopperCountriesView } from "../infrastructure/projection/personalShopperCountry/httpPersonalShopperCountriesView";
import {
  LIST_PERSONAL_SHOPPER_TEAMS,
  listPersonalShopperTeamsHandler,
} from "../projection/personalShopperTeam/listPersonalShopperTeams";
import { httpPersonalShopperTeamsView } from "../infrastructure/projection/personalShopperTeam/httpPersonalShopperTeamsView";
import {
  VIEW_PERSONAL_SHOPPER_PRODUCTION_BY_CRITERIA,
  viewPersonalShopperProductionByCriteriaHandler,
} from "../projection/personalShopperProduction/viewPersonalShopperProductionByCriteria";
import { httpPersonalShopperProductionByCriteriaView } from "../infrastructure/projection/personalShopperProduction/httpPersonalShopperProductionByCriteriaView";
import {
  VIEW_PRODUCT_VARIANT_WITH_PRODUCT_INFO_BY_ID,
  viewProductVariantWithProductInfoByIdHandler,
} from "../projection/productVariantWithProductInfo/viewProductVariantWithProductInfoById";
import { httpProductVariantWithProductInfoByIdView } from "../infrastructure/projection/productVariantWithProductInfo/httpProductVariantWithProductInfoByIdView";
import { VIEW_PRODUCT_BY_ID, viewProductByIdHandler } from "../projection/product/viewProductById";
import { httpProductByIdView } from "../infrastructure/projection/product/httpProductByIdView";
import {
  VIEW_STYLE_PROFILE_BY_BOX_ID,
  viewStyleProfileByBoxIdHandler,
} from "../projection/styleProfile/viewStyleProfileByBoxId";
import { httpStyleProfileByBoxIdView } from "../infrastructure/projection/styleProfile/httpStyleProfileByBoxIdView";
import { bootstrapWithBuilder as bootstrapNotifications } from "../../shared/notifications/infrastructure/delivery/bootstrap";
import { inMemoryStorageNotifications } from "../../shared/notifications/infrastructure/persistence/inMemoryStorageNotifications";
import {
  VIEW_SELECTION_BY_BOX_NUMBER,
  viewSelectionByBoxNumberHandler,
} from "../projection/selection/viewSelectionByBoxNumber";
import { httpSelectionByBoxNumberView } from "../infrastructure/projection/selection/httpSelectionByBoxNumberView";
import { START_AUTOMATIC_SELECTION } from "../domain/selection/command/startAutomaticSelection";
import { startAutomaticSelectionHandler } from "../domain/selection/model/automaticSelection";
import {
  getAutomaticSelection,
  saveAutomaticSelection,
} from "../infrastructure/domain/selection/model/httpAutomaticSelections";
import {
  IS_AUTOMATIC_SELECTION_ENABLED,
  isAutomaticSelectionEnabledHandler,
} from "../projection/selection/isAutomaticSelectionEnabled";
import { httpIsAutomaticSelectionEnabledView } from "../infrastructure/projection/selection/httpIsAutomaticSelectionEnabledView";
import {
  VIEW_SELECTION_ORDER_BY_BOX_NUMBER,
  viewSelectionOrderByBoxNumberHandler,
} from "../projection/selectionOrder/viewSelectionOrderByBoxNumber";
import { storageSelectionOrderByBoxNumberView } from "../infrastructure/projection/selectionOrder/storageSelectionOrderByBoxNumberView";
import { SelectionOrderProjection } from "../projection/selectionOrder/selectionOrder";
import {
  LIST_SHORTLIST_FILTERS_BY_BOX_NUMBER,
  listShortlistFiltersByBoxNumberHandler,
} from "../projection/shortlistFilter/listShortlistFiltersByBoxNumber";
import { httpShortlistFiltersByBoxNumberView } from "../infrastructure/projection/shortlistFilter/httpShortlistFiltersByBoxNumberView";
import {
  CONTAINS_EXACT_SUPERVISABLE_PRODUCT_VARIANTS_COUNT,
  containsExactSupervisableProductVariantsCountHandler,
} from "../projection/selection/containsExactSupervisableProductVariantsCount";
import { CAN_THE_BOX_BE_CLOSED, canTheBoxBeClosedHandler } from "../projection/box/canTheBoxBeClosed";
import {
  VIEW_CUSTOMER_INSIGHTS_BY_CUSTOMER_ID,
  viewCustomerInsightsByCustomerIdHandler,
} from "../projection/customerInsight/viewCustomerInsightsByCustomerId";
import { httpCustomerInsightsByCustomerIdView } from "../infrastructure/projection/customerInsight/httpCustomerInsightsByCustomerIdView";
import {
  CAN_THE_BOX_SENT_TO_SUPERVISE,
  canTheBoxSentToSuperviseHandler,
} from "../projection/box/canTheBoxSentToSupervise";
import {
  CAN_MARK_OR_UNMARK_PRODUCT_VARIANT_AS_CANDIDATE,
  canMarkOrUnmarkProductVariantAsCandidateHandler,
} from "../projection/selection/canMarkOrUnmarkProductVariantAsCandidate";

const readLooksLocalStorage: ReadFunction<LookProjection[]> = (key) => readLocalStorage(`LOOKS_${key}`);
const readSelectionOrdersLocalStorage: ReadFunction<SelectionOrderProjection> = (key) =>
  readLocalStorage(`SELECTION_ORDERS_${key}`);

const MESSAGING_CONTEXT_ID = "Labs";

interface BootstrapFunctionArgs {
  readonly apiUrl: string;
  readonly legacyApiUrl: string;
  readonly authToken: string;
}

interface BootstrapFunction {
  (args: BootstrapFunctionArgs): BuildBootstrapFunctionReturn;
}

const bootstrap: BootstrapFunction = ({ apiUrl, legacyApiUrl, authToken }) => {
  const labsBackHttpGet = fetchHttpGet({ apiUrl: () => apiUrl, getAuthToken: async () => authToken });
  const labsBackHttpPost = fetchHttpPost({ apiUrl: () => apiUrl, getAuthToken: async () => authToken });
  const labsBackLegacyHttpGet = fetchHttpGet({ apiUrl: () => legacyApiUrl, getAuthToken: async () => authToken });
  const labsBackSendBeacon = windowNavigatorSendBeacon({ apiUrl: () => apiUrl });

  const messaging = messagingBootstrap({ id: MESSAGING_CONTEXT_ID })
    .query(SEARCH_PLANNED_BOXES_BY_CRITERIA, searchPlannedBoxesByCriteriaHandler, {
      view: httpPlannedBoxesByCriteriaView({ httpLegacyGet: labsBackLegacyHttpGet, httpPost: labsBackHttpPost }),
    })
    .query(VIEW_PERSONAL_SHOPPER_FILTERING_BY_ID, viewPersonalShopperFilteringByIdHandler, {
      view: httpPersonalShopperFilteringByIdView({ httpPost: labsBackHttpPost }),
    })
    .command(SET_PERSONAL_SHOPPER_FILTERING, setPersonalShopperFilteringHandler)(
      getPersonalShopperFiltering,
      savePersonalShopperFiltering,
      { httpPost: labsBackHttpPost },
    )
    .query(VIEW_FILTERING_BY_CUSTOMER_ID, viewFilteringByCustomerIdHandler, {
      view: httpFilteringByCustomerIdView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_ANNOTATION_BY_CUSTOMER_ID, viewAnnotationByCustomerIdHandler, {
      view: httpAnnotationByCustomerIdView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_ANNOTATION_BY_ID, viewAnnotationByIdHandler, {
      view: httpAnnotationByIdView({ httpPost: labsBackHttpPost }),
    })
    .command(UPDATE_ANNOTATION, updateAnnotationHandler)(getAnnotation, saveAnnotation, {
      httpPost: labsBackHttpPost,
    })
    .query(LIST_EDITABLE_SHORTLIST_FILTERS, listEditableShortlistFiltersHandler, {
      view: httpEditableShortlistFiltersView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_UI_SETTING_BY_KEY, viewUiSettingByKeyHandler, {
      view: storageUiSettingByKeyView({ read: readLocalStorage }),
    })
    .command(UPDATE_UI_SETTING, updateUiSettingHandler)(getUiSetting, saveUiSetting, {
      read: readLocalStorage,
      write: writeLocalStorage,
    })
    .query(VIEW_FAVOURITE_BY_ID, viewFavouriteByIdHandler, {
      view: httpFavouriteByIdView({ httpPost: labsBackHttpPost }),
    })
    .query(SEARCH_FAVOURITES_BY_PS_ID, searchFavouritesByPsIdHandler, {
      view: httpFavouritesByPsIdView({ httpPost: labsBackHttpPost }),
    })
    .command(MARK_PRODUCT_AS_FAVOURITE, markProductAsFavouriteHandler)(getFavourite, saveFavourite, {
      httpPost: labsBackHttpPost,
    })
    .command(UNMARK_PRODUCT_AS_FAVOURITE, unmarkProductAsFavouriteHandler)(getFavourite, saveFavourite, {
      httpPost: labsBackHttpPost,
    })
    .query(SEARCH_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS, searchShortlistProductsByShortlistFiltersHandler, {
      view: httpShortlistProductsSearchByShortlistFiltersView({ httpPost: labsBackHttpPost }),
    })
    .query(COUNT_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS, countShortlistProductsByShortlistFiltersHandler, {
      view: httpShortlistProductsCountByShortlistFiltersView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_NOTE_TEMPLATE_BY_ID, viewNoteTemplateByIdHandler, {
      view: httpNoteTemplateByIdView({ httpPost: labsBackHttpPost }),
    })
    .query(VALIDATE_NOTE_TEMPLATE, validateNoteTemplateHandler)
    .query(LIST_NOTE_TEMPLATE_CATEGORIES_BY_PS_ID, listNoteTemplateCategoriesByPsIdHandler, {
      view: httpNoteTemplateCategoriesByPsIdView({ httpPost: labsBackHttpPost }),
    })
    .query(SEARCH_NOTE_TEMPLATES_BY_CRITERIA, searchNoteTemplatesByCriteriaHandler, {
      view: httpNoteTemplatesSearchByCriteriaView({ httpPost: labsBackHttpPost }),
    })
    .query(COUNT_NOTE_TEMPLATES_BY_CRITERIA, countNoteTemplatesByCriteriaHandler, {
      view: httpNoteTemplatesCountByCriteriaView({ httpPost: labsBackHttpPost }),
    })
    .command(WRITE_NOTE_TEMPLATE, writeNoteTemplateHandler)(getNoteTemplate, saveNoteTemplate, {
      httpPost: labsBackHttpPost,
    })
    .command(REMOVE_NOTE_TEMPLATE, removeNoteTemplateHandler)(getNoteTemplate, saveNoteTemplate, {
      httpPost: labsBackHttpPost,
    })
    .query(VIEW_FORECAST_BY_CUSTOMER_ID, viewForecastByCustomerIdHandler, {
      view: httpForecastByCustomerIdView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_NOTE_BY_BOX_ID, viewNoteByBoxIdHandler, {
      view: httpNoteByBoxIdView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_NOTE_BY_ID, viewNoteByIdHandler, {
      view: httpNoteByIdView({ httpPost: labsBackHttpPost }),
    })
    .command(WRITE_NOTE, writeNoteHandler)(getNote, saveNote, {
      httpPost: labsBackHttpPost,
    })
    .command(GENERATE_NOTE, generateNoteHandler)(getNote, saveNote, {
      httpPost: labsBackHttpPost,
    })
    .query(VIEW_PERSONAL_SHOPPER, viewPersonalShopperHandler, {
      view: cookieStoragePersonalShopperView({ decode: jwtDecode, read: readCookieStorage }),
    })
    .query(CAN_PERSONAL_SHOPPER_WRITE_NOTE, canPersonalShopperWriteNoteHandler)
    .query(CAN_PERSONAL_SHOPPER_SEND_BOXES_TO_SUPERVISE, canPersonalShopperSendBoxesToSuperviseHandler)
    .query(VIEW_BOX_BY_ID, viewBoxByIdHandler, {
      view: httpBoxByIdView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_BOX_BY_BOX_NUMBER, viewBoxByBoxNumberHandler, {
      view: httpBoxByBoxNumberView({ httpPost: labsBackHttpPost }),
    })
    .query(CAN_PERSONAL_SHOPPER_GENERATE_NOTE, canPersonalShopperGenerateNoteHandler)
    .query(VIEW_CUSTOMER_BY_ID, viewCustomerByIdHandler, { view: httpCustomerByIdView({ httpPost: labsBackHttpPost }) })
    .query(VIEW_ACTIVITY_TIME_BY_PS_ID_AND_BOX_ID, viewActivityTimeByPsIdAndBoxIdHandler, {
      view: httpActivityTimeByPsIdAndBoxIdView({ httpPost: labsBackHttpPost }),
    })
    .command(REGISTER_ACTIVITY_TIME, registerActivityTimeHandler)(getActivityTime, saveActivityTime, {
      httpPost: labsBackHttpPost,
      sendBeacon: labsBackSendBeacon,
    })
    .command(REGISTER_PREVIEWED_WATERMARK, registerPreviewedWatermarkHandler)(getActivityTime, saveActivityTime, {
      httpPost: labsBackHttpPost,
      sendBeacon: labsBackSendBeacon,
    })
    .query(IS_THE_BOX_OPEN, isTheBoxOpenHandler, { view: httpIsTheBoxOpenView({ httpPost: labsBackHttpPost }) })
    .query(VIEW_CAPACITY_CALENDAR_BY_CRITERIA, viewCapacityCalendarByCriteriaHandler, {
      view: httpCapacityCalendarByCriteriaView({ httpPost: labsBackHttpPost }),
    })
    .query(COUNT_CAPACITY_CALENDAR_BY_CRITERIA, countCapacityCalendarByCriteriaHandler, {
      view: httpCapacityCalendarCountByCriteriaView({ httpPost: labsBackHttpPost }),
    })
    .command(SYNCHRONIZE_CAPACITY_CALENDAR, synchronizeCapacityCalendarHandler)(
      getCapacityCalendar,
      saveCapacityCalendar,
      {
        httpPost: labsBackHttpPost,
      },
    )
    .query(VIEW_BOX_PREVIEW_BY_BOX_ID, viewBoxPreviewByBoxIdHandler, {
      view: httpBoxPreviewByBoxIdView({ httpPost: labsBackHttpPost }),
    })
    .query(LIST_NOTE_ALERTS_BY_BOX_NUMBER, listNoteAlertsByBoxNumberHandler, {
      view: httpNoteAlertsByBoxNumberView({ httpPost: labsBackHttpPost }),
    })
    .query(LIST_SELECTION_ALERTS_BY_BOX_NUMBER, listSelectionAlertsByBoxNumberHandler, {
      view: httpSelectionAlertsByBoxNumberView({ httpPost: labsBackHttpPost }),
    })
    .query(CONTAINS_BLOCKING_ALERTS_BY_BOX_NUMBER, containsBlockingAlertsByBoxNumberHandler)
    .query(LIST_FAMILIES, listFamiliesHandler, {
      view: httpFamiliesView({ httpPost: labsBackHttpPost }),
    })
    .query(SEARCH_HISTORY_BOXES_BY_CRITERIA, searchHistoryBoxesByCriteriaHandler, {
      view: httpHistoryBoxesByCriteriaView({ httpPost: labsBackHttpPost }),
    })
    .query(SEARCH_HISTORY_SHOP_BY_CUSTOMER_ID, searchHistoryShopByCustomerIdHandler, {
      view: httpHistoryShopByCustomerIdView({ httpPost: labsBackHttpPost }),
    })
    .query(LIST_HISTORY_NOTES_BY_CUSTOMER_ID, listHistoryNotesByCustomerIdHandler, {
      view: httpHistoryNotesByCustomerIdView({ httpPost: labsBackHttpPost }),
    })
    .query(IS_STOCK_SURVEY_SUBMITTED_BY_BOX_ID, isStockSurveySubmittedByBoxIdHandler, {
      view: httpIsStockSurveySubmittedByBoxIdView({ httpPost: labsBackHttpPost }),
    })
    .command(SUBMIT_STOCK_SURVEY, submitStockSurveyHandler)(getStockSurvey, saveStockSurvey, {
      httpPost: labsBackHttpPost,
    })
    .query(LIST_LOOKS_BY_SELECTION_ID, listLooksBySelectionIdHandler, {
      view: storageLooksBySelectionIdView({ read: readLooksLocalStorage }),
    })
    .query(VIEW_PENDING_BOXES, viewPendingBoxesHandler, {
      view: httpPendingBoxesView({ httpPost: labsBackHttpPost }),
    })
    .query(LIST_BOXES_BY_CUSTOMER_ID, listBoxesByCustomerIdHandler, {
      view: httpBoxesByCustomerIdView({ httpPost: labsBackHttpPost }),
    })
    .query(LIST_RETURN_QUESTIONS, listReturnQuestionsHandler, {
      view: httpReturnQuestionsView({ httpPost: labsBackHttpPost }),
    })
    .query(LIST_LOOK_VALIDATION_ERRORS, listLookValidationErrorsHandler)
    .query(SEARCH_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA, searchLookAndLikeFeedbacksByCriteriaHandler, {
      view: httpLookAndLikeFeedbacksSearchByCriteriaView({ httpPost: labsBackHttpPost }),
    })
    .query(COUNT_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA, countLookAndLikeFeedbacksByCriteriaHandler, {
      view: httpLookAndLikeFeedbacksCountByCriteriaView({ httpPost: labsBackHttpPost }),
    })
    .query(LIST_PERSONAL_SHOPPER_COUNTRIES, listPersonalShopperCountriesHandler, {
      view: httpPersonalShopperCountriesView({ httpGet: labsBackHttpGet }),
    })
    .query(LIST_PERSONAL_SHOPPER_TEAMS, listPersonalShopperTeamsHandler, {
      view: httpPersonalShopperTeamsView({ httpGet: labsBackHttpGet }),
    })
    .query(VIEW_PERSONAL_SHOPPER_PRODUCTION_BY_CRITERIA, viewPersonalShopperProductionByCriteriaHandler, {
      view: httpPersonalShopperProductionByCriteriaView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_PRODUCT_VARIANT_WITH_PRODUCT_INFO_BY_ID, viewProductVariantWithProductInfoByIdHandler, {
      view: httpProductVariantWithProductInfoByIdView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_PRODUCT_BY_ID, viewProductByIdHandler, {
      view: httpProductByIdView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_STYLE_PROFILE_BY_BOX_ID, viewStyleProfileByBoxIdHandler, {
      view: httpStyleProfileByBoxIdView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_SELECTION_BY_BOX_NUMBER, viewSelectionByBoxNumberHandler, {
      view: httpSelectionByBoxNumberView({ httpPost: labsBackHttpPost }),
    })
    .command(START_AUTOMATIC_SELECTION, startAutomaticSelectionHandler)(getAutomaticSelection, saveAutomaticSelection, {
      httpPost: labsBackHttpPost,
    })
    .query(IS_AUTOMATIC_SELECTION_ENABLED, isAutomaticSelectionEnabledHandler, {
      view: httpIsAutomaticSelectionEnabledView({ httpPost: labsBackHttpPost }),
    })
    .query(VIEW_SELECTION_ORDER_BY_BOX_NUMBER, viewSelectionOrderByBoxNumberHandler, {
      view: storageSelectionOrderByBoxNumberView({ read: readSelectionOrdersLocalStorage }),
    })
    .query(LIST_SHORTLIST_FILTERS_BY_BOX_NUMBER, listShortlistFiltersByBoxNumberHandler, {
      view: httpShortlistFiltersByBoxNumberView({ httpPost: labsBackHttpPost }),
    })
    .query(CONTAINS_EXACT_SUPERVISABLE_PRODUCT_VARIANTS_COUNT, containsExactSupervisableProductVariantsCountHandler)
    .query(CAN_THE_BOX_BE_CLOSED, canTheBoxBeClosedHandler)
    .query(VIEW_CUSTOMER_INSIGHTS_BY_CUSTOMER_ID, viewCustomerInsightsByCustomerIdHandler, {
      view: httpCustomerInsightsByCustomerIdView({ httpPost: labsBackHttpPost }),
    })
    .query(CAN_THE_BOX_SENT_TO_SUPERVISE, canTheBoxSentToSuperviseHandler)
    .query(CAN_MARK_OR_UNMARK_PRODUCT_VARIANT_AS_CANDIDATE, canMarkOrUnmarkProductVariantAsCandidateHandler);

  bootstrapNotifications({ storage: inMemoryStorageNotifications(), messaging });

  return messaging.build();
};

export { bootstrap, MESSAGING_CONTEXT_ID };
