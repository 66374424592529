import { FC, useCallback, useMemo } from "react";
import useEnvironment from "../../_behaviors/useEnvironment";
import { useViewUiSettingByKey } from "../../../../core/infrastructure/projection/uiSetting/react/useViewUiSettingByKey";
import UISettings from "../../../_settings/UISettings";
import { useUpdateUiSetting } from "../../../../core/infrastructure/domain/uiSetting/react/useUpdateUiSetting";
import useFeatureToggle from "../../../_featureToggle/useFeatureToggle";
import useIsFeatureToggleEnabled from "../../../../shared/featureToggle/ui/useIsFeatureToggleEnabled";
import { Sidetab } from "@typeform/embed-react";
import FeatureToggles from "../../../_featureToggle/FeatureToggles";
import { useI18nMessage } from "@lookiero/i18n-react";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../componentLibrary/_i18n/SelectionI18n";
import { useViewNoteByBoxId } from "../../../../core/infrastructure/projection/note/react/useViewNoteByBoxId";

interface NoteAssistantTypeformProps {
  readonly boxId: string;
  readonly personalShopperId: string;
}
const NoteAssistantTypeform: FC<NoteAssistantTypeformProps> = ({ boxId, personalShopperId }) => {
  const buttonText = useI18nMessage({
    id: SelectionI18n.NOTE_ASSISTANT_TYPEFORM_BUTTON,
    prefix: SELECTION_I18N_PREFIX,
  });
  const {
    typeform: { noteAssistantTypeformId },
  } = useEnvironment();

  const [note] = useViewNoteByBoxId({ boxId });

  const noteAssistantSurveyTypeformFeatureToggle = useFeatureToggle(FeatureToggles.NOTE_ASSISTANT_SURVEY_TYPEFORM);
  const noteAssistantSurveyTypeformFeatureToggleEnabled = useIsFeatureToggleEnabled({
    featureToggle: noteAssistantSurveyTypeformFeatureToggle,
  });

  const uiSettingKey = useMemo(() => `${UISettings.NOTE_ASSISTANT_TYPEFORM}_${boxId}`, [boxId]);
  const [uiSetting] = useViewUiSettingByKey({ key: uiSettingKey });
  const [updateUiSetting] = useUpdateUiSetting();
  const handleOnSubmit = useCallback(
    () =>
      updateUiSetting({
        key: uiSettingKey,
        value: true,
      }),
    [uiSettingKey, updateUiSetting],
  );

  const typeformActive = !uiSetting && noteAssistantSurveyTypeformFeatureToggleEnabled && note?.noteGenerated;

  if (!typeformActive) {
    return null;
  }

  return (
    <Sidetab
      buttonColor="black"
      buttonText={buttonText}
      id={noteAssistantTypeformId}
      open="load"
      tracking={{
        box_id: boxId,
        ps_id: personalShopperId,
      }}
      autoClose
      keepSession
      onSubmit={handleOnSubmit}
    />
  );
};

export { NoteAssistantTypeform };
