import { DomainEvent } from "@lookiero/messaging.js";

class SelectionSorted extends DomainEvent {
  public readonly legacyBoxId: string;

  public constructor(legacyBoxId: string) {
    super();

    this.legacyBoxId = legacyBoxId;
  }

  public messageName(): string {
    return "SelectionSorted";
  }
}

export default SelectionSorted;
