import { FC } from "react";
import { ActivityTimeCounter } from "../../../componentLibrary/templates/activityTimeCounter/ActivityTimeCounter";
import { useActivityTime } from "./useActivityTime";
import { useViewBoxPreviewSelectionRateByBoxId } from "./behaviors/useViewBoxPreviewSelectionRateByBoxId";
import { actionTimeForCountryByPreviewSelectionRate } from "./actionTime/actionTimeForCountryByPreviewSelectionRate";
import { useViewPersonalShopper } from "../../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import useFeatureToggle from "../../../_featureToggle/useFeatureToggle";
import FeatureToggles from "../../../_featureToggle/FeatureToggles";
import useIsFeatureToggleEnabled from "../../../../shared/featureToggle/ui/useIsFeatureToggleEnabled";

interface ActivityTimeProps {
  readonly boxId: string;
}

const ActivityTime: FC<ActivityTimeProps> = ({ boxId }) => {
  const { elapsedTime, previewedElapsedTime, enabled } = useActivityTime();
  const [personalShopper] = useViewPersonalShopper();
  const selectionRate = useViewBoxPreviewSelectionRateByBoxId({ boxId });
  const noteAssistantFeatureToggle = useFeatureToggle(FeatureToggles.NOTE_ASSISTANT);
  const noteAssistantEnabled = useIsFeatureToggleEnabled({
    featureToggle: noteAssistantFeatureToggle,
  });
  const actionTime = actionTimeForCountryByPreviewSelectionRate({
    country: personalShopper?.market,
    selectionRate,
    noteAssistantEnabled,
  });

  return (
    <ActivityTimeCounter
      actionTime={actionTime}
      elapsedTime={elapsedTime}
      enabled={enabled}
      previewedElapsedTime={previewedElapsedTime}
    />
  );
};

export { ActivityTime };
