import { DomainEvent } from "@lookiero/messaging.js";
import ProductVariantDeselected from "../../../../domain/selection/event/ProductVariantDeselected";
import ProductVariantMarkedAsCandidate from "../../../../domain/selection/event/ProductVariantMarkedAsCandidate";
import ProductVariantReplaced from "../../../../domain/selection/event/ProductVariantReplaced";
import ProductVariantSelected from "../../../../domain/selection/event/ProductVariantSelected";
import ProductVariantUnmarkedAsCandidate from "../../../../domain/selection/event/ProductVariantUnmarkedAsCandidate";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { SelectionAlertProjection } from "../../../../projection/alert/selectionAlert";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { listSelectionAlertsByBoxNumber } from "../../../../projection/alert/listSelectionAlertsByBoxNumber";
import { useMessagingJsBridgeQuery } from "../../_messagingJsBridge/useMessagingJsBridgeQuery";

const shouldInvalidate = (event: DomainEvent) =>
  event instanceof ProductVariantSelected ||
  event instanceof ProductVariantDeselected ||
  event instanceof ProductVariantReplaced ||
  event instanceof ProductVariantMarkedAsCandidate ||
  event instanceof ProductVariantUnmarkedAsCandidate;

interface UseListSelectionAlertsByBoxNumberFunctionArgs {
  readonly queryId?: string | string[];
  readonly boxNumber: string;
}

interface UseListSelectionAlertsByBoxNumberFunction {
  (args: UseListSelectionAlertsByBoxNumberFunctionArgs): UseQueryFunctionResult<SelectionAlertProjection[]>;
}

const useListSelectionAlertsByBoxNumber: UseListSelectionAlertsByBoxNumberFunction = ({ queryId, boxNumber }) =>
  useMessagingJsBridgeQuery({
    id: queryId,
    contextId: MESSAGING_CONTEXT_ID,
    query: listSelectionAlertsByBoxNumber({ boxNumber }),
    messagingJsInvalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useListSelectionAlertsByBoxNumber };
