import Country from "../../../../../core/domain/country/model/Country";
import { ActionTime, ActionTimeByCountry } from "./actionTime";

const DEFAULT_ACTION_TIME: ActionTimeByCountry = {
  [Country.FR]: 3,
  [Country.IT]: 3,
  [Country.BE]: 3,
  [Country.LU]: 3,
  [Country.DE]: 3,
  [Country.GB]: 3,
  [Country.AT]: 3,
  [Country.NL]: 3,
  [Country.ES]: 3,
  [Country.SE]: 3,
  [Country.PT]: 3,
  [Country.CH]: 3,
};

const ACTION_TIME: ActionTime = {
  /* eslint-disable @typescript-eslint/naming-convention */
  5: {
    [Country.FR]: 3,
    [Country.IT]: 3,
    [Country.BE]: 3,
    [Country.LU]: 3,
    [Country.DE]: 3,
    [Country.GB]: 3,
    [Country.AT]: 3,
    [Country.NL]: 3,
    [Country.ES]: 3,
    [Country.SE]: 3,
    [Country.PT]: 3,
    [Country.CH]: 3,
  },
  4: {
    [Country.FR]: 4.5,
    [Country.IT]: 4.5,
    [Country.BE]: 4.5,
    [Country.LU]: 4.5,
    [Country.DE]: 4.5,
    [Country.GB]: 4.5,
    [Country.AT]: 4.5,
    [Country.NL]: 4.5,
    [Country.ES]: 4.5,
    [Country.SE]: 4.5,
    [Country.PT]: 4.5,
    [Country.CH]: 4.5,
  },
  3: {
    [Country.FR]: 5.5,
    [Country.IT]: 5.5,
    [Country.BE]: 5.5,
    [Country.LU]: 5.5,
    [Country.DE]: 5.5,
    [Country.GB]: 5.5,
    [Country.AT]: 5.5,
    [Country.NL]: 5.5,
    [Country.ES]: 5.5,
    [Country.SE]: 5.5,
    [Country.PT]: 5.5,
    [Country.CH]: 5.5,
  },
  2: {
    [Country.FR]: 7,
    [Country.IT]: 7,
    [Country.BE]: 7,
    [Country.LU]: 7,
    [Country.DE]: 7,
    [Country.GB]: 8,
    [Country.AT]: 7,
    [Country.NL]: 7,
    [Country.ES]: 7,
    [Country.SE]: 7,
    [Country.PT]: 7,
    [Country.CH]: 7,
  },
  1: {
    [Country.FR]: 8,
    [Country.IT]: 8,
    [Country.BE]: 8,
    [Country.LU]: 8,
    [Country.DE]: 8,
    [Country.GB]: 9,
    [Country.AT]: 8,
    [Country.NL]: 8,
    [Country.ES]: 8,
    [Country.SE]: 8,
    [Country.PT]: 8,
    [Country.CH]: 8,
  },
  0: {
    [Country.FR]: 11,
    [Country.IT]: 11,
    [Country.BE]: 11,
    [Country.LU]: 11,
    [Country.DE]: 11,
    [Country.GB]: 11,
    [Country.AT]: 11,
    [Country.NL]: 11,
    [Country.ES]: 11,
    [Country.SE]: 11,
    [Country.PT]: 11,
    [Country.CH]: 11,
  },
  /* eslint-enable @typescript-eslint/naming-convention */
};

export { DEFAULT_ACTION_TIME, ACTION_TIME };
