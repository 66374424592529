import { DomainEvent } from "@lookiero/messaging.js";

type BoxSentToSuperviseParameters = {
  readonly boxId: string;
};
class BoxSentToSupervise extends DomainEvent {
  public readonly boxId: string;

  public constructor({ boxId }: BoxSentToSuperviseParameters) {
    super();

    this.boxId = boxId;
  }

  protected messageName(): string {
    return "BoxSentToSupervise";
  }
}

export default BoxSentToSupervise;
