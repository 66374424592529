import { AggregateRoot } from "@lookiero/messaging.js";
import BoxPreviewStatus from "../../boxPreview/model/BoxPreviewStatus";
import ProductVariantDeselected from "../event/ProductVariantDeselected";
import ProductVariantMarkedAsCandidate from "../event/ProductVariantMarkedAsCandidate";
import ProductVariantReplaced from "../event/ProductVariantReplaced";
import ProductVariantSelected from "../event/ProductVariantSelected";
import ProductVariantUnmarkedAsCandidate from "../event/ProductVariantUnmarkedAsCandidate";
import ProductVariant from "./ProductVariant";
import SelectionNotModifiable, { SelectionNotModifiableActions } from "../event/SelectionNotModifiable";
import SelectionNotModifiableError from "./SelectionNotModifiableError";
import SelectionProductVariants from "./SelectionProductVariants";

type SelectionParameters = {
  readonly legacyBoxId: string;
  readonly selectionId: string;
  readonly boxId: string;
  readonly automaticSelectionStartedOn: Date | null;
  readonly automaticSelectionFinishedOn: Date | null;
  readonly productVariants: ProductVariant[];
};

type SelectProductVariantStaticParameters = {
  readonly legacyBoxId: string;
  readonly selectionId: string;
  readonly boxId: string;
  readonly productVariantId: string;
};

type SelectProductVariantParameters = {
  readonly productVariantId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
};

type DeselectProductVariantParameters = {
  readonly productVariantId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
};

type ReplaceProductVariantParameters = {
  readonly selectedProductVariantId: string;
  readonly deselectedProductVariantId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
};

class Selection extends AggregateRoot {
  public readonly selectionId: string;
  public readonly boxId: string;
  public readonly legacyBoxId: string;
  public readonly selectionProductVariants: SelectionProductVariants;
  public readonly automaticSelectionStartedOn: Date | null;
  public readonly automaticSelectionFinishedOn: Date | null;

  public constructor({
    legacyBoxId,
    selectionId,
    boxId,
    automaticSelectionStartedOn,
    automaticSelectionFinishedOn,
    productVariants,
  }: SelectionParameters) {
    super();

    this.selectionId = selectionId;
    this.boxId = boxId;
    this.legacyBoxId = legacyBoxId;
    this.automaticSelectionStartedOn = automaticSelectionStartedOn;
    this.automaticSelectionFinishedOn = automaticSelectionFinishedOn;
    this.selectionProductVariants = new SelectionProductVariants(productVariants);
  }

  public static selectProductVariant({
    legacyBoxId,
    selectionId,
    boxId,
    productVariantId,
  }: SelectProductVariantStaticParameters): Selection {
    const instance = new Selection({
      legacyBoxId,
      selectionId,
      boxId,
      automaticSelectionStartedOn: null,
      automaticSelectionFinishedOn: null,
      productVariants: [new ProductVariant(productVariantId)],
    });
    instance.record(
      new ProductVariantSelected({ legacyBoxId, selectionId, productVariantId, productVariantIds: [productVariantId] }),
    );

    return instance;
  }

  public selectProductVariant({ productVariantId, boxPreviewStatus }: SelectProductVariantParameters): void {
    try {
      this.selectionProductVariants.select({
        boxPreviewStatus,
        automaticSelectionStartedOn: this.automaticSelectionStartedOn,
        automaticSelectionFinishedOn: this.automaticSelectionFinishedOn,
        productVariantId,
      });

      this.record(
        new ProductVariantSelected({
          legacyBoxId: this.legacyBoxId,
          selectionId: this.selectionId,
          productVariantId,
          productVariantIds: this.selectionProductVariants.productVariantIds(),
        }),
      );
    } catch (error) {
      if (error instanceof SelectionNotModifiableError) {
        this.record(new SelectionNotModifiable(SelectionNotModifiableActions.SELECT));
      }
    }
  }

  public deselectProductVariant({ productVariantId, boxPreviewStatus }: DeselectProductVariantParameters): void {
    try {
      this.selectionProductVariants.deselect({
        boxPreviewStatus,
        automaticSelectionStartedOn: this.automaticSelectionStartedOn,
        automaticSelectionFinishedOn: this.automaticSelectionFinishedOn,
        productVariantId,
      });

      this.record(
        new ProductVariantDeselected({
          legacyBoxId: this.legacyBoxId,
          selectionId: this.selectionId,
          productVariantId,
          productVariantIds: this.selectionProductVariants.productVariantIds(),
        }),
      );
    } catch (error) {
      if (error instanceof SelectionNotModifiableError) {
        this.record(new SelectionNotModifiable(SelectionNotModifiableActions.DESELECT));
      }
    }
  }

  public replaceProductVariant({
    selectedProductVariantId,
    deselectedProductVariantId,
    boxPreviewStatus,
  }: ReplaceProductVariantParameters): void {
    try {
      this.selectionProductVariants.replace({
        boxPreviewStatus,
        automaticSelectionStartedOn: this.automaticSelectionStartedOn,
        automaticSelectionFinishedOn: this.automaticSelectionFinishedOn,
        selectedProductVariantId,
        deselectedProductVariantId,
      });

      this.record(
        new ProductVariantReplaced({
          legacyBoxId: this.legacyBoxId,
          selectionId: this.selectionId,
          selectedProductVariantId,
          deselectedProductVariantId,
          productVariantIds: this.selectionProductVariants.productVariantIds(),
        }),
      );
    } catch (error) {
      if (error instanceof SelectionNotModifiableError) {
        this.record(new SelectionNotModifiable(SelectionNotModifiableActions.REPLACE));
      }
    }
  }

  public markProductVariantAsCandidate(productVariantId: string): void {
    this.selectionProductVariants.markAsCandidate(productVariantId);

    this.record(new ProductVariantMarkedAsCandidate(this.selectionId, productVariantId));
  }

  public unmarkProductVariantAsCandidate(productVariantId: string): void {
    this.selectionProductVariants.unmarkAsCandidate(productVariantId);

    this.record(new ProductVariantUnmarkedAsCandidate(this.selectionId, productVariantId));
  }
}

export default Selection;
