import { FC, useCallback, useMemo, useRef, useState } from "react";
import ProductPreview from "../../../../componentLibrary/organisms/productPreview/ProductPreview";
import { Routes } from "../../../../_routing/Routing";
import useBoxSharedContext from "../../../_behaviors/useBoxSharedContext";
import useDeselectProductVariant from "../../../_behaviors/useDeselectProductVariant";
import useProductVariantComparer from "../../../_behaviors/useProductVariantComparer";
import useReplaceProductVariant from "../../../_behaviors/useReplaceProductVariant";
import SelectionProductPreviewAlerts from "../../../../componentLibrary/organisms/selectionProductPreviewAlerts/SelectionProductPreviewAlerts";
import AsyncActionState from "../../../../../shared/ui/uiKit/_common/AsyncActionState";
import ProductPreviewActionButton from "../../../../componentLibrary/molecules/productPreviewActionButton/ProductPreviewActionButton";
import { IconVariant } from "../../../../../shared/ui/uiKit/atoms/icon/Icon";
import CandidateTag from "./candidateTag/CandidateTag";
import SelectionProductPreviewCandidateActions from "./selectionProductPreviewCandidateActions/SelectionProductPreviewCandidateActions";
import useMarkProductVariantAsCandidate from "../../../_behaviors/useMarkProductVariantAsCandidate";
import useUnmarkProductVariantAsCandidate from "../../../_behaviors/useUnmarkProductVariantAsCandidate";
import { useCanMarkOrUnmarkProductVariantAsCandidate } from "../../../../../core/infrastructure/projection/selection/react/useCanMarkOrUnmarkProductVariantAsCandidate";
import DeselectProductVariantConfirmationModal from "../../../../componentLibrary/organisms/deselectProductVariantConfirmationModal/DeselectProductVariantConfirmationModal";
import useProductVariantSelection from "../../../_behaviors/useProductVariantSelection";
import useFavourites from "../../../_behaviors/useFavourites";
import { generatePath, useNavigate } from "react-router-dom";
import { useViewBoxPreviewByBoxId } from "../../../../../core/infrastructure/projection/boxPreview/react/useViewBoxPreviewByBoxId";
import { SelectionAlertProjection } from "../../../../../core/projection/alert/selectionAlert";
import { useListLooksForSelection } from "../../../../../core/infrastructure/projection/looks/react/useListLooksForSelection";
import { TrackingPage } from "../../../../../shared/tracking/Tracker";
import "./selection-product-preview.css";
import {
  isProductVariantIdIncludedInSelection,
  SelectionProductProjection,
  SelectionProductVariantProjection,
} from "../../../../../core/projection/selection/selection";

interface OnDeselectProductVariantFunctionArgs {
  readonly position: number;
  readonly productVariantId: string;
}
interface OnDeselectProductVariantFunction {
  (args: OnDeselectProductVariantFunctionArgs): void;
}

interface OnClickProductFunctionArgs {
  readonly position: number;
  readonly productId: string;
}
interface OnClickProductFunction {
  (args: OnClickProductFunctionArgs): void;
}

type SelectionProductPreviewProps = {
  readonly product: SelectionProductProjection;
  readonly alerts?: SelectionAlertProjection[];
  readonly locale: string;
  readonly position?: number;
  readonly onDeselectProductVariant?: OnDeselectProductVariantFunction;
  readonly onClickProduct?: OnClickProductFunction;
};

const SelectionProductPreview: FC<SelectionProductPreviewProps> = ({
  product,
  alerts,
  locale,
  position = 0,
  onDeselectProductVariant,
  onClickProduct,
}: SelectionProductPreviewProps) => {
  const { box, selection } = useBoxSharedContext();
  const [boxPreview] = useViewBoxPreviewByBoxId({ boxId: box.id });
  const { add: addProductVariantToComparer } = useProductVariantComparer();
  const { state: deselectProductVariantState, deselectProductVariant } = useDeselectProductVariant({
    legacyBoxId: String(box.legacyId),
    boxPreviewStatus: boxPreview?.status,
  });
  const { state: replaceProductVariantState, replaceProductVariant } = useReplaceProductVariant({
    legacyBoxId: String(box.legacyId),
    boxPreviewStatus: boxPreview?.status,
  });
  const { state: markProductVariantAsCandidateState, markProductVariantAsCandidate } = useMarkProductVariantAsCandidate(
    {
      legacyBoxId: String(box.legacyId),
      selectionId: selection?.selectionId,
      boxId: box.id,
    },
  );
  const { state: unmarkProductVariantAsCandidateState, unmarkProductVariantAsCandidate } =
    useUnmarkProductVariantAsCandidate({
      legacyBoxId: String(box.legacyId),
      selectionId: selection?.selectionId,
      boxId: box.id,
    });
  const [canMarkOrUnmarkProductVariantAsCandidate] = useCanMarkOrUnmarkProductVariantAsCandidate({
    boxNumber: String(box.boxNumber),
    boxId: box.id,
  });
  const [looks] = useListLooksForSelection({ selection });

  const {
    isFavourite,
    markProductAsFavouriteState,
    markProductAsFavourite,
    unmarkProductAsFavouriteState,
    unmarkProductAsFavourite,
  } = useFavourites({ product, section: TrackingPage.SELECTION, position });

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  navigateRef.current = navigate;
  const handleProductPreviewClick = useCallback(() => {
    onClickProduct?.({ position, productId: product.id });

    const productLink = generatePath(Routes.BOX_SELECTION_PRODUCT, {
      locale,
      product: product.id,
      box: String(box.legacyId),
    });

    navigateRef.current(productLink);
  }, [box.legacyId, locale, onClickProduct, position, product.id]);

  const selectionProductVariantId = product.productVariants.find((productVariant: SelectionProductVariantProjection) =>
    isProductVariantIdIncludedInSelection(selection, productVariant.id),
  )?.id;
  const selectionProductVariant = product.productVariants.find(
    (productVariant) => productVariant.id === selectionProductVariantId,
  );
  const [productVariant, setProductVariant] = useProductVariantSelection({
    selectionProductVariant,
    product,
  });

  const replacedFor = useMemo(
    () => (selectionProductVariantId ? boxPreview?.replacedFor[selectionProductVariantId] : undefined),
    [boxPreview?.replacedFor, selectionProductVariantId],
  );

  const handleOnReplaceProductVariant = useCallback(
    async (productVariantToReplace: SelectionProductVariantProjection) => {
      try {
        await replaceProductVariant(productVariantToReplace, productVariant);
        setProductVariant(productVariantToReplace);
      } catch {}
    },
    [productVariant, replaceProductVariant, setProductVariant],
  );
  const handleOnAddToComparer = useCallback(
    () => addProductVariantToComparer(product.id, productVariant),
    [addProductVariantToComparer, product.id, productVariant],
  );

  const [deselectConfirmationVisible, setDeselectConfirmationVisible] = useState(false);
  const showDeselectConfirmationModal = useCallback(() => setDeselectConfirmationVisible(true), []);
  const hideDeselectConfirmationModal = useCallback(() => setDeselectConfirmationVisible(false), []);
  const handleDeselectConfirmed = useCallback(() => {
    hideDeselectConfirmationModal();
    deselectProductVariant(productVariant);
    onDeselectProductVariant?.({ position, productVariantId: productVariant.id });
  }, [deselectProductVariant, hideDeselectConfirmationModal, onDeselectProductVariant, position, productVariant]);

  const handleOnDeselectProductVariant = useCallback(() => {
    const isProductVariantIncludedInALook = looks.some((look) =>
      look.some((lookProductVariantId) => lookProductVariantId === productVariant.id),
    );

    if (isProductVariantIncludedInALook) {
      showDeselectConfirmationModal();
    } else {
      deselectProductVariant(productVariant);
      onDeselectProductVariant?.({ position, productVariantId: productVariant.id });
    }
  }, [
    deselectProductVariant,
    looks,
    onDeselectProductVariant,
    position,
    productVariant,
    showDeselectConfirmationModal,
  ]);

  const actions = useMemo(
    () => (
      <>
        <div>
          {isFavourite ? (
            <ProductPreviewActionButton
              icon={IconVariant.HEART_FILLED}
              label="unmark-product-as-favourite"
              state={unmarkProductAsFavouriteState}
              onClick={unmarkProductAsFavourite}
            />
          ) : (
            <ProductPreviewActionButton
              icon={IconVariant.HEART}
              label="mark-product-as-favourite"
              state={markProductAsFavouriteState}
              onClick={markProductAsFavourite}
            />
          )}
          <ProductPreviewActionButton
            icon={IconVariant.COMPARE}
            label="add-to-comparer"
            onClick={handleOnAddToComparer}
          />
        </div>
        <div>
          {canMarkOrUnmarkProductVariantAsCandidate && (
            <SelectionProductPreviewCandidateActions
              markAsCandidateState={markProductVariantAsCandidateState}
              selectedProductVariant={productVariant}
              unmarkAsCandidateState={unmarkProductVariantAsCandidateState}
              onMarkAsCandidate={markProductVariantAsCandidate}
              onUnmarkAsCandidate={unmarkProductVariantAsCandidate}
            />
          )}
          <ProductPreviewActionButton
            icon={IconVariant.MINUS}
            label="deselect-product-variant"
            state={deselectProductVariantState}
            onClick={handleOnDeselectProductVariant}
          />
        </div>
      </>
    ),
    [
      canMarkOrUnmarkProductVariantAsCandidate,
      deselectProductVariantState,
      handleOnAddToComparer,
      handleOnDeselectProductVariant,
      isFavourite,
      markProductAsFavourite,
      markProductAsFavouriteState,
      markProductVariantAsCandidate,
      markProductVariantAsCandidateState,
      productVariant,
      unmarkProductAsFavourite,
      unmarkProductAsFavouriteState,
      unmarkProductVariantAsCandidate,
      unmarkProductVariantAsCandidateState,
    ],
  );

  return useMemo(
    () => (
      <>
        <div
          aria-label="selection-product-preview"
          className="selection-product-preview"
          role="listitem"
          onClick={handleProductPreviewClick}
        >
          <ProductPreview
            actions={actions}
            alerts={<SelectionProductPreviewAlerts alerts={alerts} productVariant={productVariant} />}
            candidate={<CandidateTag selectedProductVariant={productVariant} />}
            disabled={replaceProductVariantState === AsyncActionState.PENDING}
            product={product}
            productVariant={productVariant}
            replacedFor={replacedFor}
            onProductVariantChanged={handleOnReplaceProductVariant}
          />
        </div>
        <DeselectProductVariantConfirmationModal
          visible={deselectConfirmationVisible}
          onCancel={hideDeselectConfirmationModal}
          onConfirm={handleDeselectConfirmed}
        />
      </>
    ),
    [
      handleProductPreviewClick,
      actions,
      alerts,
      productVariant,
      replaceProductVariantState,
      product,
      replacedFor,
      handleOnReplaceProductVariant,
      deselectConfirmationVisible,
      hideDeselectConfirmationModal,
      handleDeselectConfirmed,
    ],
  );
};

export default SelectionProductPreview;
