enum FeatureToggles {
  REAL_TIME_BOX_PRODUCTION = "REAL_TIME_BOX_PRODUCTION",
  STOCK_SURVEY_TYPEFORM = "STOCK_SURVEY_TYPEFORM",
  NOTE_ASSISTANT = "NOTE_ASSISTANT",
  NOTE_ASSISTANT_SURVEY_TYPEFORM = "NOTE_ASSISTANT_SURVEY_TYPEFORM",
  AUTOMATIC_SELECTION_TYPEFORM = "AUTOMATIC_SELECTION_TYPEFORM",
  CUSTOMER_INSIGHTS = "CUSTOMER_INSIGHTS",
}

export default FeatureToggles;
