import { Command } from "@lookiero/messaging.js";
import BoxPreviewStatus from "../../../boxPreview/model/BoxPreviewStatus";

type ReplaceProductVariantParameters = {
  readonly legacyBoxId: string;
  readonly selectedProductVariantId: string;
  readonly deselectedProductVariantId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
};

class ReplaceProductVariant extends Command {
  public readonly legacyBoxId: string;
  public readonly selectedProductVariantId: string;
  public readonly deselectedProductVariantId: string;
  public readonly boxPreviewStatus: BoxPreviewStatus | undefined;

  public constructor({
    legacyBoxId,
    selectedProductVariantId,
    deselectedProductVariantId,
    boxPreviewStatus,
  }: ReplaceProductVariantParameters) {
    super();
    this.legacyBoxId = legacyBoxId;
    this.selectedProductVariantId = selectedProductVariantId;
    this.deselectedProductVariantId = deselectedProductVariantId;
    this.boxPreviewStatus = boxPreviewStatus;
  }

  protected messageName(): string {
    return "ReplaceProductVariant";
  }
}

export default ReplaceProductVariant;
