import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { UiSetting } from "../../../../domain/uiSetting/model/uiSetting";
import { UiSettingGetFunction, UiSettingSaveFunction } from "../../../../domain/uiSetting/model/uiSettings";
import { ReadFunction, WriteFunction } from "../../../persistence/storage";
import { UiSettingDto } from "../../../persistence/uiSettingDto";

const toDomain = (uiSettingDto: UiSettingDto): UiSetting => ({
  aggregateId: uiSettingDto.id,
  key: uiSettingDto.key,
  value: uiSettingDto.value,
  domainEvents: [],
});

const fromDomain = (uiSetting: UiSetting): UiSettingDto => ({
  id: uiSetting.aggregateId,
  key: uiSetting.key,
  value: uiSetting.value,
});

interface StorageUiSettingsGetFunctionArgs extends RepositoryGetFunctionArgs {
  readonly read: ReadFunction<UiSettingDto>;
}

interface StorageUiSettingsGetFunction extends UiSettingGetFunction<StorageUiSettingsGetFunctionArgs> {}

const getUiSetting: StorageUiSettingsGetFunction =
  ({ read }) =>
  async (uiSettingKey) => {
    const uiSettingDto = await read(uiSettingKey);

    if (!uiSettingDto) {
      throw new Error(`UiSetting not found: ${uiSettingKey}`);
    }

    return toDomain(uiSettingDto);
  };

interface StorageUiSettingsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly write: WriteFunction<UiSettingDto>;
}

interface StorageUiSettingsSaveFunction extends UiSettingSaveFunction<StorageUiSettingsSaveFunctionArgs> {}

const saveUiSetting: StorageUiSettingsSaveFunction =
  ({ write }) =>
  async (aggregateRoot) =>
    await write(aggregateRoot.key, fromDomain(aggregateRoot));

export { getUiSetting, saveUiSetting };
