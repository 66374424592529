import { DomainEvent } from "@lookiero/messaging.js";

export enum SelectionNotModifiableActions {
  SELECT = "select",
  DESELECT = "deselect",
  REPLACE = "replace",
}

class SelectionNotModifiable extends DomainEvent {
  public readonly action: SelectionNotModifiableActions;

  public constructor(action: SelectionNotModifiableActions) {
    super();
    this.action = action;
  }

  protected override messageName(): string {
    return "SelectionNotModifiable";
  }
}

export default SelectionNotModifiable;
