import { CommandHandler } from "@lookiero/messaging.js";
import SelectionOrder from "../../model/SelectionOrder";
import SelectionOrders from "../../model/SelectionOrders";
import SortSelection from "./SortSelection";

class SortSelectionHandler implements CommandHandler<SortSelection> {
  private readonly selectionOrders: SelectionOrders;

  public constructor(selectionOrders: SelectionOrders) {
    this.selectionOrders = selectionOrders;
  }

  public async execute({ legacyBoxId, selectionId, productVariantIds }: SortSelection): Promise<void> {
    let selectionOrder: SelectionOrder | undefined = await this.selectionOrders.byLegacyBoxId(legacyBoxId);

    if (!selectionOrder) {
      selectionOrder = SelectionOrder.sort({ legacyBoxId, selectionId, productVariantIds });
    } else {
      selectionOrder.sort(productVariantIds);
    }

    await this.selectionOrders.sort(selectionOrder);
  }
}

export default SortSelectionHandler;
