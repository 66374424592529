import { AggregateRoot } from "@lookiero/messaging.js";
import LooksSet from "../event/LooksSet";
import LookDTO from "./LookDTO";

const VALID_LOOK_LENGTHS = [0, 2, 3, 4];

type LookParameters = {
  readonly selectionId: string;
  readonly looks: LookDTO[];
};

class Look extends AggregateRoot {
  public readonly selectionId: string;
  public looks: LookDTO[];

  public constructor({ selectionId, looks }: LookParameters) {
    super();

    this.selectionId = selectionId;
    this.looks = looks;
  }

  private static areLooksValid(looks: LookDTO[]): void | never {
    if (looks.some((look) => !VALID_LOOK_LENGTHS.includes(look.length))) {
      throw new Error("Invalid looks length");
    }

    if (looks.some((look) => look.some((productVariant) => !Boolean(productVariant)))) {
      throw new Error("Looks cannot contain empty product variant");
    }

    if (looks.some((look) => look.length !== new Set(look).size)) {
      throw new Error("Looks cannot contain duplicate product variant");
    }
  }

  public static set({ selectionId, looks }: LookParameters): Look {
    Look.areLooksValid(looks);

    const instance = new Look({ selectionId, looks });

    instance.record(new LooksSet(instance.selectionId));

    return instance;
  }

  public set(looks: LookDTO[]): void {
    Look.areLooksValid(looks);

    this.looks = looks;

    this.record(new LooksSet(this.selectionId));
  }
}

export default Look;
