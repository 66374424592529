import { Command } from "@lookiero/messaging.js";

class RemoveSelectionOrder extends Command {
  public readonly legacyBoxId: string;

  public constructor(legacyBoxId: string) {
    super();
    this.legacyBoxId = legacyBoxId;
  }

  protected messageName(): string {
    return "RemoveSelectionOrder";
  }
}

export default RemoveSelectionOrder;
