import { DomainEvent } from "@lookiero/messaging.js";
import { useMemo } from "react";
import { LookProjection } from "../../../../projection/looks/look";
import LooksSet from "../../../../domain/look/event/LooksSet";
import { useMessagingJsBridgeQuery } from "../../_messagingJsBridge/useMessagingJsBridgeQuery";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { listLooksBySelectionId } from "../../../../projection/looks/listLooksBySelectionId";
import { QueryStatus } from "@lookiero/messaging-react";
import { ProductVariantProjection } from "../../../../projection/product/productVariant";
import { SelectionProjection } from "../../../../projection/selection/selection";

const shouldInvalidate = (event: DomainEvent) => event instanceof LooksSet;

type UseListLooksForSelectionResult = [projection: LookProjection[], status: QueryStatus];

interface UseListLooksForSelectionFunctionArgs {
  readonly selection: SelectionProjection | undefined;
}

interface UseListLooksForSelectionFunction {
  (args: UseListLooksForSelectionFunctionArgs): UseListLooksForSelectionResult;
}

const useListLooksForSelection: UseListLooksForSelectionFunction = ({ selection }) => {
  const [looks = [], status] = useMessagingJsBridgeQuery<LookProjection[]>({
    contextId: MESSAGING_CONTEXT_ID,
    query: listLooksBySelectionId({ selectionId: selection?.selectionId as string }),
    options: {
      staleTime: Infinity,
      enabled: Boolean(selection?.selectionId),
    },
    messagingJsInvalidation: shouldInvalidate,
  });

  const selectionProductVariants = useMemo(
    () => selection?.products.map((p) => p.productVariants.find((pv) => pv.isSelected) as ProductVariantProjection),
    [selection?.products],
  );

  /**
   * Filter out invalid looks that they don't match the actual selection
   */
  const sanitizedLooks = looks?.reduce((accLooks: LookProjection[], look: LookProjection) => {
    const notFoundProductVariantWithinSelection = look.some(
      (pvId) => !Boolean(selectionProductVariants?.find((spv) => spv.id === pvId)),
    );

    return notFoundProductVariantWithinSelection ? accLooks : [...accLooks, look];
  }, [] as LookProjection[]);

  return [sanitizedLooks, status];
};

export { useListLooksForSelection };
