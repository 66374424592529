import { CommandHandler } from "@lookiero/messaging.js";
import { QueryBus as MessagingQueryBus } from "@lookiero/messaging";
import Boxes from "../../model/Boxes";
import Box from "../../model/Box";
import SendBoxToSupervise from "./SendBoxToSupervise";
import {
  canTheBoxSentToSupervise,
  CanTheBoxSentToSuperviseResult,
} from "../../../../projection/box/canTheBoxSentToSupervise";

class SendBoxToSuperviseHandler implements CommandHandler<SendBoxToSupervise> {
  private readonly messagingQueryBus!: MessagingQueryBus;
  private readonly boxes: Boxes;

  public constructor(boxes: Boxes) {
    this.boxes = boxes;
  }

  public async execute({ legacyBoxId, personalShopperId }: SendBoxToSupervise): Promise<void> {
    const box: Box | undefined = await this.boxes.byLegacyBoxId(legacyBoxId);

    if (!box) {
      throw new Error("Cannot send the box to supervise. The box does not exist");
    }

    const canTheBoxBeSentToSuperviseResult: CanTheBoxSentToSuperviseResult = await this.messagingQueryBus(
      canTheBoxSentToSupervise({ boxNumber: legacyBoxId }),
    );

    if (!canTheBoxBeSentToSuperviseResult) {
      throw new Error("Cannot send the box to supervise.");
    }

    box.sendToSupervise();

    await this.boxes.sendToSupervise(box, personalShopperId);
  }
}

export default SendBoxToSuperviseHandler;
