import { Command } from "@lookiero/messaging.js";

type SendBoxToSuperviseParameters = {
  readonly legacyBoxId: string;
  readonly personalShopperId: string;
};
class SendBoxToSupervise extends Command {
  public readonly legacyBoxId: string;
  public readonly personalShopperId: string;

  public constructor({ legacyBoxId, personalShopperId }: SendBoxToSuperviseParameters) {
    super();

    this.legacyBoxId = legacyBoxId;
    this.personalShopperId = personalShopperId;
  }

  protected messageName(): string {
    return "SendBoxToSupervise";
  }
}

export default SendBoxToSupervise;
