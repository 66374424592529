import { EventBus } from "@lookiero/messaging.js";
import LocalStorage from "../../../../../shared/storage/infrastructure/domain/model/LocalStorage";
import Look from "../../../../domain/look/model/Look";
import Looks from "../../../../domain/look/model/Looks";
import LookDTO from "../../../../domain/look/model/LookDTO";

class LocalStorageLooks implements Looks {
  private readonly storage: LocalStorage<LookDTO[]>;
  private readonly eventBus!: EventBus;

  public constructor(storage: LocalStorage<LookDTO[]>) {
    this.storage = storage;
  }

  public async bySelectionId(selectionId: string): Promise<Look | undefined> {
    const looks = await this.storage.read(selectionId);

    return looks ? new Look({ selectionId, looks }) : undefined;
  }

  public async set(look: Look): Promise<void> {
    if (look.looks.length === 0) {
      await this.storage.remove(look.selectionId);
    } else {
      await this.storage.write(look.selectionId, look.looks);
    }

    this.eventBus.publish(look.recordedEvents());
  }
}

export default LocalStorageLooks;
