import { UiSettingByKeyView, UiSettingProjection } from "../../../projection/uiSetting/viewUiSettingByKey";
import { UiSettingDto } from "../../persistence/uiSettingDto";
import { ReadFunction } from "../../persistence/storage";

interface ToUiSettingProjectionFunction {
  (uiSettingDto: UiSettingDto): UiSettingProjection;
}

const toUiSettingProjection: ToUiSettingProjectionFunction = (uiSettingDto) => ({
  id: uiSettingDto.id,
  key: uiSettingDto.key,
  value: uiSettingDto.value,
});

interface StorageUiSettingByKeyView extends UiSettingByKeyView {}

interface StorageUiSettingByKeyViewFunctionArgs {
  readonly read: ReadFunction<UiSettingDto>;
}

interface StorageUiSettingByKeyViewFunction {
  (args: StorageUiSettingByKeyViewFunctionArgs): StorageUiSettingByKeyView;
}

const storageUiSettingByKeyView: StorageUiSettingByKeyViewFunction =
  ({ read }) =>
  async ({ key }) => {
    try {
      const uiSettingDto = await read(key);

      return uiSettingDto ? toUiSettingProjection(uiSettingDto) : null;
    } catch (ignored) {
      throw new Error("Could not fetch the uiSetting");
    }
  };

export { storageUiSettingByKeyView };
