enum AspectRatio {
  R_16_9 = "16-9",
  R_4_3 = "4-3",
  R_3_2 = "3-2",
  R_2_3 = "2-3",
  R_4_5 = "4-5",
  R_1_1 = "1-1",
}

export default AspectRatio;
