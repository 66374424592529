import { DomainEvent as DomainEventJs } from "@lookiero/messaging.js";
import BoxClosed from "../../../../domain/box/event/BoxClosed";
import BoxPreviewSent from "../../../../domain/boxPreview/event/BoxPreviewSent";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { useMessagingJsBridgeQuery } from "../../_messagingJsBridge/useMessagingJsBridgeQuery";
import {
  canMarkOrUnmarkProductVariantAsCandidate,
  CanMarkOrUnmarkProductVariantAsCandidateResult,
} from "../../../../projection/selection/canMarkOrUnmarkProductVariantAsCandidate";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

interface UseCanMarkOrUnmarkProductVariantAsCandidateFunctionArgs {
  readonly boxNumber: string;
  readonly boxId: string;
}

interface UseCanMarkOrUnmarkProductVariantAsCandidateFunction {
  (
    args: UseCanMarkOrUnmarkProductVariantAsCandidateFunctionArgs,
  ): UseQueryFunctionResult<CanMarkOrUnmarkProductVariantAsCandidateResult>;
}

const useCanMarkOrUnmarkProductVariantAsCandidate: UseCanMarkOrUnmarkProductVariantAsCandidateFunction = ({
  boxNumber,
  boxId,
}) =>
  useMessagingJsBridgeQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: canMarkOrUnmarkProductVariantAsCandidate({ boxNumber, boxId }),
    messagingJsInvalidation: (event: DomainEventJs) => event instanceof BoxClosed || event instanceof BoxPreviewSent,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useCanMarkOrUnmarkProductVariantAsCandidate };
