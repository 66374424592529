import { DomainEvent } from "@lookiero/messaging.js";
import BoxClosed from "../../../../domain/box/event/BoxClosed";
import BoxSentToSupervise from "../../../../domain/box/event/BoxSentToSupervise";
import { BoxProjection } from "../../../../projection/box/box";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { viewBoxByBoxNumber } from "../../../../projection/box/viewBoxByBoxNumber";
import { useMessagingJsBridgeQuery } from "../../_messagingJsBridge/useMessagingJsBridgeQuery";

const shouldInvalidate = (event: DomainEvent) => event instanceof BoxClosed || event instanceof BoxSentToSupervise;

interface UseViewBoxByBoxNumberFunctionArgs {
  readonly boxNumber: string;
}

interface UseViewBoxByBoxNumberFunction {
  (args: UseViewBoxByBoxNumberFunctionArgs): UseQueryFunctionResult<BoxProjection>;
}

const useViewBoxByBoxNumber: UseViewBoxByBoxNumberFunction = ({ boxNumber }) =>
  useMessagingJsBridgeQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewBoxByBoxNumber({ boxNumber }),
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
    messagingJsInvalidation: shouldInvalidate,
  });

export { useViewBoxByBoxNumber };
