import { useDispatch } from "@lookiero/messaging.js";
import { useCallback, useState } from "react";
import AsyncActionState from "../../../shared/ui/uiKit/_common/AsyncActionState";
import CloseBox from "../../../core/domain/box/command/closeBox/CloseBox";

type UseCloseBoxReturn = {
  readonly state: AsyncActionState;
  readonly closeBox: () => Promise<void>;
};
const useCloseBox = (legacyBoxId: string): UseCloseBoxReturn => {
  const dispatch = useDispatch();
  const [state, setState] = useState(AsyncActionState.DEFAULT);

  const closeBox = useCallback(async () => {
    try {
      setState(AsyncActionState.PENDING);

      await dispatch(new CloseBox(legacyBoxId));

      setState(AsyncActionState.SUCCESS);
    } catch (error) {
      console.log(error);
      setState(AsyncActionState.ERROR);
    }
  }, [dispatch, legacyBoxId]);

  return { state, closeBox };
};

export default useCloseBox;
