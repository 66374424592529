import { Command } from "@lookiero/messaging.js";

class CloseBox extends Command {
  public readonly legacyBoxId: string;

  public constructor(legacyBoxId: string) {
    super();

    this.legacyBoxId = legacyBoxId;
  }

  protected messageName(): string {
    return "CloseBox";
  }
}

export default CloseBox;
