import { CommandHandler } from "@lookiero/messaging.js";
import Look from "../../model/Look";
import Looks from "../../model/Looks";
import SetLooks from "./SetLooks";

class SetLooksHandler implements CommandHandler<SetLooks> {
  private readonly looks: Looks;

  public constructor(looks: Looks) {
    this.looks = looks;
  }

  public async execute({ selectionId, looks }: SetLooks): Promise<void> {
    let look = await this.looks.bySelectionId(selectionId);

    if (!look) {
      look = Look.set({ selectionId, looks });
    } else {
      look.set(looks);
    }

    await this.looks.set(look);
  }
}

export default SetLooksHandler;
