import Sizing from "../../../domain/sizing/model/Sizing";
import Brand from "../../../projection/brand/model/Brand";
import Color from "../../../projection/color/model/Color";
import Media from "../../../projection/media/model/Media";
import Price from "../../../projection/price/model/Price";
import {
  SelectionProductProjection,
  SelectionProductVariantProjection,
  SelectionProjection,
} from "../../../projection/selection/selection";
import Size from "../../../projection/size/model/Size";

interface SelectionProductVariantDto {
  readonly id: string;
  readonly isSelected: boolean;
  readonly isCandidate: boolean;
  readonly hasIssue: boolean;
  readonly season: number;
  readonly recommended: boolean;
  readonly score: number;
  readonly color: Color;
  readonly size: Size;
  readonly price: Price;
  readonly media: Media[];
}

interface SelectionProductDto {
  readonly id: string;
  readonly group: string;
  readonly title: string;
  readonly brand: Brand;
  readonly sizing: string;
  readonly productVariants: SelectionProductVariantDto[];
}

interface SelectionDto {
  readonly selectionId: string;
  readonly boxId: string;
  readonly products: SelectionProductDto[];
  readonly automaticSelectionStartedOn: string | null;
  readonly automaticSelectionFinishedOn: string | null;
}

interface ToProductVariantProjectionFunction {
  (selectionProductVariantDto: SelectionProductVariantDto): SelectionProductVariantProjection;
}

const toProductVariantProjection: ToProductVariantProjectionFunction = ({
  id,
  isSelected,
  isCandidate,
  hasIssue,
  season,
  recommended,
  score,
  color,
  size,
  price,
  media,
}) => ({
  id,
  isSelected,
  isCandidate,
  hasIssue,
  season,
  recommended,
  score,
  color,
  size,
  price,
  media: media as Media[],
});

interface ToProductProjectionFunction {
  (selectionProductDto: SelectionProductDto): SelectionProductProjection;
}

const toProductProjection: ToProductProjectionFunction = ({ id, group, title, brand, sizing, productVariants }) => ({
  id,
  group,
  title,
  brand,
  sizing: sizing as Sizing,
  productVariants: productVariants.map(toProductVariantProjection),
});

interface ToSelectionProjectionFunction {
  (selectionDto: SelectionDto): SelectionProjection;
}

const toSelectionProjection: ToSelectionProjectionFunction = ({
  selectionId,
  boxId,
  products,
  automaticSelectionStartedOn,
  automaticSelectionFinishedOn,
}) => ({
  selectionId,
  boxId,
  products: products.map(toProductProjection),
  automaticSelectionStartedOn: automaticSelectionStartedOn ? new Date(automaticSelectionStartedOn) : null,
  automaticSelectionFinishedOn: automaticSelectionFinishedOn ? new Date(automaticSelectionFinishedOn) : null,
});

export type { SelectionDto };
export { toSelectionProjection };
