import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import { QueryBus as MessagingQueryBus } from "@lookiero/messaging";
import SortSelection from "../command/sortSelection/SortSelection";
import ProductVariantDeselected from "../../selection/event/ProductVariantDeselected";
import {
  viewSelectionOrderByBoxNumber,
  ViewSelectionOrderByBoxNumberResult,
} from "../../../projection/selectionOrder/viewSelectionOrderByBoxNumber";

class SortSelectionWhenProductVariantDeselected implements ProcessManager<ProductVariantDeselected> {
  private readonly commandBus!: CommandBus;
  private readonly messagingQueryBus!: MessagingQueryBus;

  public async process({
    legacyBoxId,
    selectionId,
    productVariantId,
    productVariantIds,
  }: ProductVariantDeselected): Promise<void> {
    const selectionOrder: ViewSelectionOrderByBoxNumberResult = await this.messagingQueryBus(
      viewSelectionOrderByBoxNumber({ boxNumber: legacyBoxId }),
    );

    const selectionOrderProductVariantIds = selectionOrder ? selectionOrder.productVariantIds : productVariantIds;

    this.commandBus.dispatch(
      new SortSelection({
        legacyBoxId,
        selectionId,
        productVariantIds: selectionOrderProductVariantIds.filter((id: string) => id !== productVariantId),
      }),
    );
  }
}

export default SortSelectionWhenProductVariantDeselected;
