import { Command } from "@lookiero/messaging.js";

type SortSelectionParameters = {
  readonly legacyBoxId: string;
  readonly selectionId: string;
  readonly productVariantIds: string[];
};

class SortSelection extends Command {
  public readonly legacyBoxId: string;
  public readonly selectionId: string;
  public readonly productVariantIds: string[];

  public constructor({ legacyBoxId, selectionId, productVariantIds }: SortSelectionParameters) {
    super();
    this.legacyBoxId = legacyBoxId;
    this.selectionId = selectionId;
    this.productVariantIds = productVariantIds;
  }

  protected messageName(): string {
    return "SortSelection";
  }
}

export default SortSelection;
