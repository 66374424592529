import LookDTO from "../../../../domain/look/model/LookDTO";

type BoxPreviewByBoxIdRequestPayload = {
  readonly box_id: string;
};
const boxPreviewByBoxIdRequestPayload = (boxId: string): BoxPreviewByBoxIdRequestPayload => ({
  box_id: boxId,
});

type SendBoxPreviewRequestPayload = {
  readonly box_preview_id: string;
  readonly box_id: string;
  readonly looks: LookDTO[] | undefined;
};
const sendBoxPreviewRequestPayload = (
  boxPreviewId: string,
  boxId: string,
  looks: LookDTO[] | undefined,
): SendBoxPreviewRequestPayload => ({
  box_preview_id: boxPreviewId,
  box_id: boxId,
  looks,
});

export { boxPreviewByBoxIdRequestPayload, sendBoxPreviewRequestPayload };
