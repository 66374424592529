import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import { QueryBus as MessagingQueryBus } from "@lookiero/messaging";
import SortSelection from "../command/sortSelection/SortSelection";
import ProductVariantSelected from "../../selection/event/ProductVariantSelected";
import {
  ViewSelectionOrderByBoxNumberResult,
  viewSelectionOrderByBoxNumber,
} from "../../../projection/selectionOrder/viewSelectionOrderByBoxNumber";

class SortSelectionWhenProductVariantSelected implements ProcessManager<ProductVariantSelected> {
  private readonly commandBus!: CommandBus;
  private readonly messagingQueryBus!: MessagingQueryBus;

  public async process({
    legacyBoxId,
    selectionId,
    productVariantId,
    productVariantIds,
  }: ProductVariantSelected): Promise<void> {
    const selectionOrder: ViewSelectionOrderByBoxNumberResult = await this.messagingQueryBus(
      viewSelectionOrderByBoxNumber({ boxNumber: legacyBoxId }),
    );

    this.commandBus.dispatch(
      new SortSelection({
        legacyBoxId,
        selectionId,
        productVariantIds: selectionOrder ? [...selectionOrder.productVariantIds, productVariantId] : productVariantIds,
      }),
    );
  }
}

export default SortSelectionWhenProductVariantSelected;
