import { CommandHandler } from "@lookiero/messaging.js";
import Selection from "../../model/Selection";
import Selections from "../../model/Selections";
import SelectProductVariant from "./SelectProductVariant";

class SelectProductVariantHandler implements CommandHandler<SelectProductVariant> {
  private readonly selections: Selections;

  public constructor(selections: Selections) {
    this.selections = selections;
  }

  public async execute({
    legacyBoxId,
    boxId,
    selectionId,
    productVariantId,
    boxPreviewStatus,
  }: SelectProductVariant): Promise<void> {
    let selection: Selection | undefined = await this.selections.byLegacyBoxId(legacyBoxId);

    if (!selection) {
      selection = Selection.selectProductVariant({
        legacyBoxId,
        selectionId,
        boxId,
        productVariantId,
      });
    } else {
      selection.selectProductVariant({
        productVariantId,
        boxPreviewStatus,
      });
    }

    await this.selections.selectProductVariant(selection, productVariantId);
  }
}

export default SelectProductVariantHandler;
