import { CommandHandler } from "@lookiero/messaging.js";
import SelectionOrder from "../../model/SelectionOrder";
import SelectionOrders from "../../model/SelectionOrders";
import RemoveSelectionOrder from "./RemoveSelectionOrder";

class RemoveSelectionOrderHandler implements CommandHandler<RemoveSelectionOrder> {
  private readonly selectionOrders: SelectionOrders;

  public constructor(selectionOrders: SelectionOrders) {
    this.selectionOrders = selectionOrders;
  }

  public async execute({ legacyBoxId }: RemoveSelectionOrder): Promise<void> {
    const selectionOrder: SelectionOrder | undefined = await this.selectionOrders.byLegacyBoxId(legacyBoxId);

    if (!selectionOrder) {
      return;
    }

    await this.selectionOrders.remove(legacyBoxId);
  }
}

export default RemoveSelectionOrderHandler;
