import { DomainEvent } from "@lookiero/messaging.js";

type ProductVariantDeselectedParameters = {
  readonly legacyBoxId: string;
  readonly selectionId: string;
  readonly productVariantId: string;
  readonly productVariantIds: string[];
};

class ProductVariantDeselected extends DomainEvent {
  public readonly legacyBoxId: string;
  public readonly selectionId: string;
  public readonly productVariantId: string;
  public readonly productVariantIds: string[];

  public constructor({
    legacyBoxId,
    selectionId,
    productVariantId,
    productVariantIds,
  }: ProductVariantDeselectedParameters) {
    super();

    this.legacyBoxId = legacyBoxId;
    this.selectionId = selectionId;
    this.productVariantId = productVariantId;
    this.productVariantIds = productVariantIds;
  }

  public messageName(): string {
    return "ProductVariantDeselected";
  }
}

export default ProductVariantDeselected;
