import { DomainEvent } from "@lookiero/messaging.js";

type ProductVariantReplacedParameters = {
  readonly legacyBoxId: string;
  readonly selectionId: string;
  readonly selectedProductVariantId: string;
  readonly deselectedProductVariantId: string;
  readonly productVariantIds: string[];
};

class ProductVariantReplaced extends DomainEvent {
  public readonly legacyBoxId: string;
  public readonly selectionId: string;
  public readonly selectedProductVariantId: string;
  public readonly deselectedProductVariantId: string;
  public readonly productVariantIds: string[];

  public constructor({
    legacyBoxId,
    selectionId,
    selectedProductVariantId,
    deselectedProductVariantId,
    productVariantIds,
  }: ProductVariantReplacedParameters) {
    super();

    this.legacyBoxId = legacyBoxId;
    this.selectionId = selectionId;
    this.selectedProductVariantId = selectedProductVariantId;
    this.deselectedProductVariantId = deselectedProductVariantId;
    this.productVariantIds = productVariantIds;
  }

  public messageName(): string {
    return "ProductVariantReplaced";
  }
}

export default ProductVariantReplaced;
